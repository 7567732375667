export const dealsList = [
  {
    "id": "1",
    "deal-id": "DTX1",
    "description": "",
    "from-date": "2024-12-19T00:00:00Z",
    "to-date": "2024-12-19T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": false,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": [
          {
            "country": "GB",
            "region": "WND",
            "city": "Bedford"
          },
          {
            "country": "GB",
            "region": "BDF",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "MA",
            "metro": "506",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "IN",
            "metro": "527",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "IA",
            "metro": "679",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "KY",
            "metro": "529",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "PA",
            "metro": "574",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "VA",
            "metro": "573",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "VA",
            "metro": "544",
            "city": "Suffolk"
          }
        ]
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SupplyAdministrator102@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239365,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "automation1666990859",
    "state": "Stopped",
    "tracking-pixel": {}
  },
  {
    "id": "2",
    "deal-id": "DTX2",
    "description": "",
    "from-date": "2024-12-19T00:00:00Z",
    "to-date": "2024-12-19T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": false,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": [
          {
            "country": "GB",
            "region": "ABD",
            "city": "Ballater",
            "zipCode": "AB35"
          },
          {
            "country": "GB",
            "region": "BAS",
            "city": "Bath",
            "zipCode": "BA1"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "South Brewham",
            "zipCode": "BA10"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Frome",
            "zipCode": "BA11"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Newbury",
            "zipCode": "BA12"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Westbury",
            "zipCode": "BA13"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "West Ashton",
            "zipCode": "BA14"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Bradford-on-Avon",
            "zipCode": "BA15"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Street",
            "zipCode": "BA16"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Farleigh Hungerford",
            "zipCode": "BA2"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Yeovil",
            "zipCode": "BA20"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Yeovil",
            "zipCode": "BA21"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "East Coker",
            "zipCode": "BA22"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Gurney Slade",
            "zipCode": "BA3"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Doulting",
            "zipCode": "BA4"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Westbury-sub-Mendip",
            "zipCode": "BA5"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "West Pennard",
            "zipCode": "BA6"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Castle Cary",
            "zipCode": "BA7"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Templecombe",
            "zipCode": "BA8"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Wincanton",
            "zipCode": "BA9"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Rishton",
            "zipCode": "BB1"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Burnley",
            "zipCode": "BB10"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Hapton",
            "zipCode": "BB11"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Read",
            "zipCode": "BB12"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Earby",
            "zipCode": "BB18"
          },
          {
            "country": "GB",
            "region": "BBD",
            "city": "Blackburn",
            "zipCode": "BB2"
          },
          {
            "country": "GB",
            "region": "BBD",
            "city": "Darwen",
            "zipCode": "BB3"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Rawtenstall",
            "zipCode": "BB4"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Oswaldtwistle",
            "zipCode": "BB5"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Langho",
            "zipCode": "BB6"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Barrow",
            "zipCode": "BB7"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Colne",
            "zipCode": "BB8"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Nelson",
            "zipCode": "BB9"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD1"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Shipley",
            "zipCode": "BD10"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Drighlington",
            "zipCode": "BD11"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD12"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Queensbury",
            "zipCode": "BD13"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Clayton",
            "zipCode": "BD14"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD15"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bingley",
            "zipCode": "BD16"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Shipley",
            "zipCode": "BD17"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Shipley",
            "zipCode": "BD18"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Cleckheaton",
            "zipCode": "BD19"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD2"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Lothersdale",
            "zipCode": "BD20"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Keighley",
            "zipCode": "BD21"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Cowling",
            "zipCode": "BD22"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Thornton-in-Craven",
            "zipCode": "BD23"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Settle",
            "zipCode": "BD24"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD3"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD4"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD5"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Wibsey",
            "zipCode": "BD6"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD7"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD8"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Bradford",
            "zipCode": "BD9"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Wool",
            "zipCode": "BH20"
          },
          {
            "country": "GB",
            "region": "BUR",
            "city": "Ramsbottom",
            "zipCode": "BL0"
          },
          {
            "country": "GB",
            "region": "BOL",
            "city": "Bolton",
            "zipCode": "BL1"
          },
          {
            "country": "GB",
            "region": "BUR",
            "city": "Ainsworth",
            "zipCode": "BL2"
          },
          {
            "country": "GB",
            "region": "BOL",
            "city": "Bolton",
            "zipCode": "BL3"
          },
          {
            "country": "GB",
            "region": "BOL",
            "city": "Kearsley",
            "zipCode": "BL4"
          },
          {
            "country": "GB",
            "region": "BOL",
            "city": "Westhoughton",
            "zipCode": "BL5"
          },
          {
            "country": "GB",
            "region": "BOL",
            "city": "Horwich",
            "zipCode": "BL6"
          },
          {
            "country": "GB",
            "region": "BBD",
            "city": "Edgworth",
            "zipCode": "BL7"
          },
          {
            "country": "GB",
            "region": "BUR",
            "city": "Tottington",
            "zipCode": "BL8"
          },
          {
            "country": "GB",
            "region": "BUR",
            "city": "Bury",
            "zipCode": "BL9"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS1"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS10"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS11"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS13"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS14"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS15"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS16"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS2"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Portishead",
            "zipCode": "BS20"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Kenn",
            "zipCode": "BS21"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Weston-super-Mare",
            "zipCode": "BS22"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Weston-super-Mare",
            "zipCode": "BS23"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Weston-super-Mare",
            "zipCode": "BS24"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Shipham",
            "zipCode": "BS25"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Axbridge",
            "zipCode": "BS26"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Cheddar",
            "zipCode": "BS27"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Wedmore",
            "zipCode": "BS28"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Banwell",
            "zipCode": "BS29"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS3"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS30"
          },
          {
            "country": "GB",
            "region": "BAS",
            "city": "Saltford",
            "zipCode": "BS31"
          },
          {
            "country": "GB",
            "region": "SGC",
            "city": "Bradley Stoke",
            "zipCode": "BS32"
          },
          {
            "country": "GB",
            "region": "SGC",
            "city": "Stoke Gifford",
            "zipCode": "BS34"
          },
          {
            "country": "GB",
            "region": "SGC",
            "city": "Kington",
            "zipCode": "BS35"
          },
          {
            "country": "GB",
            "region": "SGC",
            "city": "Frampton Cotterell",
            "zipCode": "BS36"
          },
          {
            "country": "GB",
            "region": "SGC",
            "city": "Old Sodbury",
            "zipCode": "BS37"
          },
          {
            "country": "GB",
            "region": "BAS",
            "city": "High Littleton",
            "zipCode": "BS39"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS4"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Wrington",
            "zipCode": "BS40"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Long Ashton",
            "zipCode": "BS41"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Wraxall",
            "zipCode": "BS48"
          },
          {
            "country": "GB",
            "region": "NSM",
            "city": "Cleeve",
            "zipCode": "BS49"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS5"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS6"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS7"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Clifton",
            "zipCode": "BS8"
          },
          {
            "country": "GB",
            "region": "BST",
            "city": "Bristol",
            "zipCode": "BS9"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT1"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT10"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT11"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT12"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT13"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT14"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT15"
          },
          {
            "country": "GB",
            "region": "LBC",
            "city": "Dundonald",
            "zipCode": "BT16"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT17"
          },
          {
            "country": "GB",
            "region": "AND",
            "city": "Holywood",
            "zipCode": "BT18"
          },
          {
            "country": "GB",
            "region": "AND",
            "city": "Bangor",
            "zipCode": "BT19"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT2"
          },
          {
            "country": "GB",
            "region": "AND",
            "city": "Bangor",
            "zipCode": "BT20"
          },
          {
            "country": "GB",
            "region": "AND",
            "city": "Donaghadee",
            "zipCode": "BT21"
          },
          {
            "country": "GB",
            "region": "AND",
            "city": "Portaferry",
            "zipCode": "BT22"
          },
          {
            "country": "GB",
            "region": "AND",
            "city": "Newtownards",
            "zipCode": "BT23"
          },
          {
            "country": "GB",
            "region": "NMD",
            "city": "Saintfield",
            "zipCode": "BT24"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Dromore",
            "zipCode": "BT25"
          },
          {
            "country": "GB",
            "region": "LBC",
            "city": "Hillsborough",
            "zipCode": "BT26"
          },
          {
            "country": "GB",
            "region": "LBC",
            "city": "Lisburn",
            "zipCode": "BT27"
          },
          {
            "country": "GB",
            "region": "LBC",
            "city": "Lisburn",
            "zipCode": "BT28"
          },
          {
            "country": "GB",
            "region": "ANN",
            "city": "Crumlin",
            "zipCode": "BT29"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT3"
          },
          {
            "country": "GB",
            "region": "NMD",
            "city": "Downpatrick",
            "zipCode": "BT30"
          },
          {
            "country": "GB",
            "region": "NMD",
            "city": "Castlewellan",
            "zipCode": "BT31"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Banbridge",
            "zipCode": "BT32"
          },
          {
            "country": "GB",
            "region": "NMD",
            "city": "Bryansford",
            "zipCode": "BT33"
          },
          {
            "country": "GB",
            "region": "NMD",
            "city": "Warrenpoint",
            "zipCode": "BT34"
          },
          {
            "country": "GB",
            "region": "NMD",
            "city": "Newry",
            "zipCode": "BT35"
          },
          {
            "country": "GB",
            "region": "ANN",
            "city": "Newtownabbey",
            "zipCode": "BT36"
          },
          {
            "country": "GB",
            "region": "ANN",
            "city": "Newtownabbey",
            "zipCode": "BT37"
          },
          {
            "country": "GB",
            "region": "MEA",
            "city": "Whitehead",
            "zipCode": "BT38"
          },
          {
            "country": "GB",
            "region": "ANN",
            "city": "Straid",
            "zipCode": "BT39"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT4"
          },
          {
            "country": "GB",
            "region": "MEA",
            "city": "Larne",
            "zipCode": "BT40"
          },
          {
            "country": "GB",
            "region": "ANN",
            "city": "Toomebridge",
            "zipCode": "BT41"
          },
          {
            "country": "GB",
            "region": "MEA",
            "city": "Ballymena",
            "zipCode": "BT42"
          },
          {
            "country": "GB",
            "region": "MEA",
            "city": "Ballymena",
            "zipCode": "BT43"
          },
          {
            "country": "GB",
            "region": "MEA",
            "city": "Portglenone",
            "zipCode": "BT44"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Tobermore",
            "zipCode": "BT45"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Swatragh",
            "zipCode": "BT46"
          },
          {
            "country": "GB",
            "region": "DRS",
            "city": "Eglinton",
            "zipCode": "BT47"
          },
          {
            "country": "GB",
            "region": "DRS",
            "city": "Londonderry",
            "zipCode": "BT48"
          },
          {
            "country": "GB",
            "region": "CCG",
            "city": "Limavady",
            "zipCode": "BT49"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Castlereagh",
            "zipCode": "BT5"
          },
          {
            "country": "GB",
            "region": "CCG",
            "city": "Kilrea",
            "zipCode": "BT51"
          },
          {
            "country": "GB",
            "region": "CCG",
            "city": "Coleraine",
            "zipCode": "BT52"
          },
          {
            "country": "GB",
            "region": "CCG",
            "city": "Ballymoney",
            "zipCode": "BT53"
          },
          {
            "country": "GB",
            "region": "CCG",
            "city": "Portstewart",
            "zipCode": "BT55"
          },
          {
            "country": "GB",
            "region": "CCG",
            "city": "Portrush",
            "zipCode": "BT56"
          },
          {
            "country": "GB",
            "region": "CCG",
            "city": "Bushmills",
            "zipCode": "BT57"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT6"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Armagh",
            "zipCode": "BT60"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Loughgall",
            "zipCode": "BT61"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Tandragee",
            "zipCode": "BT62"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Craigavon",
            "zipCode": "BT63"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Craigavon",
            "zipCode": "BT64"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Craigavon",
            "zipCode": "BT65"
          },
          {
            "country": "GB",
            "region": "ABC",
            "city": "Waringstown",
            "zipCode": "BT66"
          },
          {
            "country": "GB",
            "region": "LBC",
            "city": "Moira",
            "zipCode": "BT67"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Aughnacloy",
            "zipCode": "BT69"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT7"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Pomeroy",
            "zipCode": "BT70"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Dungannon",
            "zipCode": "BT71"
          },
          {
            "country": "GB",
            "region": "FMO",
            "city": "Tamlaght",
            "zipCode": "BT74"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Fivemiletown",
            "zipCode": "BT75"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Clogher",
            "zipCode": "BT76"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Augher",
            "zipCode": "BT77"
          },
          {
            "country": "GB",
            "region": "FMO",
            "city": "Trillick",
            "zipCode": "BT78"
          },
          {
            "country": "GB",
            "region": "FMO",
            "city": "Sixmilecross",
            "zipCode": "BT79"
          },
          {
            "country": "GB",
            "region": "LBC",
            "city": "Carryduff",
            "zipCode": "BT8"
          },
          {
            "country": "GB",
            "region": "MUL",
            "city": "Cookstown",
            "zipCode": "BT80"
          },
          {
            "country": "GB",
            "region": "DRS",
            "city": "Castlederg",
            "zipCode": "BT81"
          },
          {
            "country": "GB",
            "region": "DRS",
            "city": "Strabane",
            "zipCode": "BT82"
          },
          {
            "country": "GB",
            "region": "BFS",
            "city": "Belfast",
            "zipCode": "BT9"
          },
          {
            "country": "GB",
            "region": "FMO",
            "city": "Rosslea",
            "zipCode": "BT92"
          },
          {
            "country": "GB",
            "region": "FMO",
            "city": "Kesh",
            "zipCode": "BT93"
          },
          {
            "country": "GB",
            "region": "FMO",
            "city": "Lisbellaw",
            "zipCode": "BT94"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Keswick",
            "zipCode": "CA12"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Cardiff",
            "zipCode": "CF10"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Cardiff",
            "zipCode": "CF11"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Cardiff",
            "zipCode": "CF14"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Tongwynlais",
            "zipCode": "CF15"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Cardiff",
            "zipCode": "CF23"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Cardiff",
            "zipCode": "CF24"
          },
          {
            "country": "GB",
            "region": "NWP",
            "city": "Newport",
            "zipCode": "CF3"
          },
          {
            "country": "GB",
            "region": "BGE",
            "city": "Bridgend",
            "zipCode": "CF31"
          },
          {
            "country": "GB",
            "region": "VGL",
            "city": "Southerndown",
            "zipCode": "CF32"
          },
          {
            "country": "GB",
            "region": "BGE",
            "city": "Bridgend",
            "zipCode": "CF33"
          },
          {
            "country": "GB",
            "region": "BGE",
            "city": "Maesteg",
            "zipCode": "CF34"
          },
          {
            "country": "GB",
            "region": "BGE",
            "city": "Pencoed",
            "zipCode": "CF35"
          },
          {
            "country": "GB",
            "region": "BGE",
            "city": "Porthcawl",
            "zipCode": "CF36"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Treforest",
            "zipCode": "CF37"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Church Village",
            "zipCode": "CF38"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Tonyrefail",
            "zipCode": "CF39"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Porth",
            "zipCode": "CF40"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Rhondda",
            "zipCode": "CF41"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Treorchy",
            "zipCode": "CF42"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Penrhys",
            "zipCode": "CF43"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Hirwaun",
            "zipCode": "CF44"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Mountain Ash",
            "zipCode": "CF45"
          },
          {
            "country": "GB",
            "region": "MTY",
            "city": "Trelewis",
            "zipCode": "CF46"
          },
          {
            "country": "GB",
            "region": "MTY",
            "city": "Merthyr Tydfil",
            "zipCode": "CF47"
          },
          {
            "country": "GB",
            "region": "MTY",
            "city": "Pentrebach",
            "zipCode": "CF48"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Ely",
            "zipCode": "CF5"
          },
          {
            "country": "GB",
            "region": "VGL",
            "city": "Llantwit Major",
            "zipCode": "CF61"
          },
          {
            "country": "GB",
            "region": "VGL",
            "city": "Vale of Glamorgan",
            "zipCode": "CF62"
          },
          {
            "country": "GB",
            "region": "VGL",
            "city": "Barry",
            "zipCode": "CF63"
          },
          {
            "country": "GB",
            "region": "VGL",
            "city": "Sully",
            "zipCode": "CF64"
          },
          {
            "country": "GB",
            "region": "VGL",
            "city": "Cowbridge",
            "zipCode": "CF71"
          },
          {
            "country": "GB",
            "region": "RCT",
            "city": "Talbot Green",
            "zipCode": "CF72"
          },
          {
            "country": "GB",
            "region": "CAY",
            "city": "Pontlottyn",
            "zipCode": "CF81"
          },
          {
            "country": "GB",
            "region": "CAY",
            "city": "Ystrad Mynach",
            "zipCode": "CF82"
          },
          {
            "country": "GB",
            "region": "CRF",
            "city": "Thornhill",
            "zipCode": "CF83"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Chester",
            "zipCode": "CH1"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Chester",
            "zipCode": "CH2"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Cheshire West and Chester",
            "zipCode": "CH3"
          },
          {
            "country": "GB",
            "region": "FLN",
            "city": "Bretton",
            "zipCode": "CH4"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Birkenhead",
            "zipCode": "CH41"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Rock Ferry",
            "zipCode": "CH42"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Birkenhead",
            "zipCode": "CH43"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Wallasey",
            "zipCode": "CH44"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Wallasey",
            "zipCode": "CH45"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Moreton",
            "zipCode": "CH46"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Hoylake",
            "zipCode": "CH47"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "West Kirby",
            "zipCode": "CH48"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Woodchurch",
            "zipCode": "CH49"
          },
          {
            "country": "GB",
            "region": "FLN",
            "city": "Shotton",
            "zipCode": "CH5"
          },
          {
            "country": "GB",
            "region": "FLN",
            "city": "Flintshire",
            "zipCode": "CH6"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Heswall",
            "zipCode": "CH60"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Barnston",
            "zipCode": "CH61"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Eastham",
            "zipCode": "CH62"
          },
          {
            "country": "GB",
            "region": "WRL",
            "city": "Higher Bebington",
            "zipCode": "CH63"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Parkgate",
            "zipCode": "CH64"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Ellesmere Port",
            "zipCode": "CH65"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Little Sutton",
            "zipCode": "CH66"
          },
          {
            "country": "GB",
            "region": "FLN",
            "city": "Rhosesmor",
            "zipCode": "CH7"
          },
          {
            "country": "GB",
            "region": "FLN",
            "city": "Holywell",
            "zipCode": "CH8"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Little Cornard",
            "zipCode": "CO10"
          },
          {
            "country": "GB",
            "region": "CHE",
            "city": "Swettenham",
            "zipCode": "CW12"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Utkinton",
            "zipCode": "CW6"
          },
          {
            "country": "GB",
            "region": "DND",
            "city": "Dundee",
            "zipCode": "DD1"
          },
          {
            "country": "GB",
            "region": "ANS",
            "city": "Montrose",
            "zipCode": "DD10"
          },
          {
            "country": "GB",
            "region": "ANS",
            "city": "Friockheim",
            "zipCode": "DD11"
          },
          {
            "country": "GB",
            "region": "DND",
            "city": "Dundee",
            "zipCode": "DD2"
          },
          {
            "country": "GB",
            "region": "DND",
            "city": "Dundee",
            "zipCode": "DD3"
          },
          {
            "country": "GB",
            "region": "ANS",
            "city": "Kirkton of Tealing",
            "zipCode": "DD4"
          },
          {
            "country": "GB",
            "region": "ANS",
            "city": "Monifieth",
            "zipCode": "DD5"
          },
          {
            "country": "GB",
            "region": "FIF",
            "city": "Wormit",
            "zipCode": "DD6"
          },
          {
            "country": "GB",
            "region": "ANS",
            "city": "Carnoustie",
            "zipCode": "DD7"
          },
          {
            "country": "GB",
            "region": "ANS",
            "city": "Kirriemuir",
            "zipCode": "DD8"
          },
          {
            "country": "GB",
            "region": "ANS",
            "city": "Brechin",
            "zipCode": "DD9"
          },
          {
            "country": "GB",
            "region": "DER",
            "city": "Derby",
            "zipCode": "DE1"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Blackfordby",
            "zipCode": "DE11"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Swadlincote",
            "zipCode": "DE12"
          },
          {
            "country": "GB",
            "region": "STS",
            "city": "Hoar Cross",
            "zipCode": "DE13"
          },
          {
            "country": "GB",
            "region": "STS",
            "city": "Burton-on-Trent",
            "zipCode": "DE14"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Stanton",
            "zipCode": "DE15"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Horsley",
            "zipCode": "DE21"
          },
          {
            "country": "GB",
            "region": "DER",
            "city": "Derby",
            "zipCode": "DE22"
          },
          {
            "country": "GB",
            "region": "DER",
            "city": "Derby",
            "zipCode": "DE23"
          },
          {
            "country": "GB",
            "region": "DER",
            "city": "Derby",
            "zipCode": "DE24"
          },
          {
            "country": "GB",
            "region": "DER",
            "city": "Derby",
            "zipCode": "DE3"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Ambergate",
            "zipCode": "DE56"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Yeaveley",
            "zipCode": "DE6"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Hilton",
            "zipCode": "DE65"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Aston-on-Trent",
            "zipCode": "DE72"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Ticknall",
            "zipCode": "DE73"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Hemington",
            "zipCode": "DE74"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Dumfries",
            "zipCode": "DG1"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Moffat",
            "zipCode": "DG10"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Lockerbie",
            "zipCode": "DG11"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Annan",
            "zipCode": "DG12"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Dumfries",
            "zipCode": "DG2"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Thornhill",
            "zipCode": "DG3"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Dalbeattie",
            "zipCode": "DG5"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "New Galloway",
            "zipCode": "DG7"
          },
          {
            "country": "GB",
            "region": "DGY",
            "city": "Stranraer",
            "zipCode": "DG9"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Durham",
            "zipCode": "DH1"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Ouston",
            "zipCode": "DH2"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Chester-le-Street",
            "zipCode": "DH3"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Sunderland",
            "zipCode": "DH4"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Sunderland",
            "zipCode": "DH5"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Thornley",
            "zipCode": "DH6"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Esh Winning",
            "zipCode": "DH7"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Consett",
            "zipCode": "DH8"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Durham",
            "zipCode": "DH9"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Wolsingham",
            "zipCode": "DL13"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Leeholme",
            "zipCode": "DL14"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Howden-le-Wear",
            "zipCode": "DL15"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Spennymoor",
            "zipCode": "DL16"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Chilton",
            "zipCode": "DL17"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Shildon",
            "zipCode": "DL4"
          },
          {
            "country": "GB",
            "region": "NLN",
            "city": "South Ferriby",
            "zipCode": "DN18"
          },
          {
            "country": "GB",
            "region": "NLN",
            "city": "Goxhill",
            "zipCode": "DN19"
          },
          {
            "country": "GB",
            "region": "NLN",
            "city": "Wressle",
            "zipCode": "DN20"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Searby",
            "zipCode": "DN38"
          },
          {
            "country": "GB",
            "region": "NLN",
            "city": "Ulceby",
            "zipCode": "DN39"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Dorchester",
            "zipCode": "DT1"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Todber",
            "zipCode": "DT10"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Winterborne Monkton",
            "zipCode": "DT2"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Weymouth",
            "zipCode": "DT3"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Weymouth",
            "zipCode": "DT4"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Portland",
            "zipCode": "DT5"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Charmouth",
            "zipCode": "DT6"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Lyme Regis",
            "zipCode": "DT7"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Beaminster",
            "zipCode": "DT8"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Charlton Horethorne",
            "zipCode": "DT9"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Honiton",
            "zipCode": "EX14"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Willand",
            "zipCode": "EX15"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Puddington",
            "zipCode": "EX16"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Winkleigh",
            "zipCode": "EX20"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Shebbear",
            "zipCode": "EX21"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Holsworthy",
            "zipCode": "EX22"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Bude",
            "zipCode": "EX23"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Bickington",
            "zipCode": "EX31"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "West Buckland",
            "zipCode": "EX32"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Georgeham",
            "zipCode": "EX33"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Woolacombe",
            "zipCode": "EX34"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Lynton",
            "zipCode": "EX35"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "South Molton",
            "zipCode": "EX36"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Umberleigh",
            "zipCode": "EX37"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Great Torrington",
            "zipCode": "EX38"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Northam",
            "zipCode": "EX39"
          },
          {
            "country": "GB",
            "region": "GLS",
            "city": "Wotton-under-Edge",
            "zipCode": "GL12"
          },
          {
            "country": "GB",
            "region": "GLS",
            "city": "Sharpness",
            "zipCode": "GL13"
          },
          {
            "country": "GB",
            "region": "SGC",
            "city": "Badminton",
            "zipCode": "GL9"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Huddersfield",
            "zipCode": "HD1"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Huddersfield",
            "zipCode": "HD2"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Huddersfield",
            "zipCode": "HD3"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Huddersfield",
            "zipCode": "HD4"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Huddersfield",
            "zipCode": "HD5"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Huddersfield",
            "zipCode": "HD6"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Slaithwaite",
            "zipCode": "HD7"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Skelmanthorpe",
            "zipCode": "HD8"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Meltham",
            "zipCode": "HD9"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Harrogate",
            "zipCode": "HG1"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Harrogate",
            "zipCode": "HG2"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Summerbridge",
            "zipCode": "HG3"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Ripon",
            "zipCode": "HG4"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Farnham",
            "zipCode": "HG5"
          },
          {
            "country": "GB",
            "region": "HEF",
            "city": "Marden",
            "zipCode": "HR1"
          },
          {
            "country": "GB",
            "region": "HEF",
            "city": "Harewood End",
            "zipCode": "HR2"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Hay-on-Wye",
            "zipCode": "HR3"
          },
          {
            "country": "GB",
            "region": "HEF",
            "city": "Herefordshire",
            "zipCode": "HR4"
          },
          {
            "country": "GB",
            "region": "HEF",
            "city": "Lyonshall",
            "zipCode": "HR5"
          },
          {
            "country": "GB",
            "region": "HEF",
            "city": "Stockton",
            "zipCode": "HR6"
          },
          {
            "country": "GB",
            "region": "HEF",
            "city": "Bromyard",
            "zipCode": "HR7"
          },
          {
            "country": "GB",
            "region": "GLS",
            "city": "Preston",
            "zipCode": "HR8"
          },
          {
            "country": "GB",
            "region": "HEF",
            "city": "Bromsash",
            "zipCode": "HR9"
          },
          {
            "country": "GB",
            "region": "ELS",
            "city": "Lochmaddy",
            "zipCode": "HS6"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU1"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU10"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Great Hatfield",
            "zipCode": "HU11"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Winestead",
            "zipCode": "HU12"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU13"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Melton",
            "zipCode": "HU14"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Newport",
            "zipCode": "HU15"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Cottingham",
            "zipCode": "HU16"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Leven",
            "zipCode": "HU17"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU2"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU3"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU4"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU5"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU6"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU7"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU8"
          },
          {
            "country": "GB",
            "region": "KHL",
            "city": "Hull",
            "zipCode": "HU9"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Halifax",
            "zipCode": "HX1"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Wainstalls",
            "zipCode": "HX2"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Halifax",
            "zipCode": "HX3"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Halifax",
            "zipCode": "HX4"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Elland",
            "zipCode": "HX5"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Sowerby Bridge",
            "zipCode": "HX6"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Hebden Bridge",
            "zipCode": "HX7"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Ipswich",
            "zipCode": "IP1"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Levington",
            "zipCode": "IP10"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Felixstowe",
            "zipCode": "IP11"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Woodbridge",
            "zipCode": "IP12"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Hacheston",
            "zipCode": "IP13"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Combs",
            "zipCode": "IP14"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Aldeburgh",
            "zipCode": "IP15"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Benhall",
            "zipCode": "IP17"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Halesworth",
            "zipCode": "IP19"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Ipswich",
            "zipCode": "IP2"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Harleston",
            "zipCode": "IP20"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Stradbroke",
            "zipCode": "IP21"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Wortham",
            "zipCode": "IP22"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Thorndon",
            "zipCode": "IP23"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Thetford",
            "zipCode": "IP24"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Watton",
            "zipCode": "IP25"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Methwold Hythe",
            "zipCode": "IP26"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Lakenheath",
            "zipCode": "IP27"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Red Lodge",
            "zipCode": "IP28"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Lawshall",
            "zipCode": "IP29"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Ipswich",
            "zipCode": "IP3"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Woolpit",
            "zipCode": "IP30"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Thurston",
            "zipCode": "IP31"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Bury St Edmunds",
            "zipCode": "IP32"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Bury St Edmunds",
            "zipCode": "IP33"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Ipswich",
            "zipCode": "IP4"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Ipswich",
            "zipCode": "IP5"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Barham",
            "zipCode": "IP6"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Naughton",
            "zipCode": "IP7"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Ipswich",
            "zipCode": "IP8"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Ipswich",
            "zipCode": "IP9"
          },
          {
            "country": "GB",
            "region": "MRY",
            "city": "Lhanbryde",
            "zipCode": "IV30"
          },
          {
            "country": "GB",
            "region": "MRY",
            "city": "Garmouth",
            "zipCode": "IV32"
          },
          {
            "country": "GB",
            "region": "MRY",
            "city": "Kinloss",
            "zipCode": "IV36"
          },
          {
            "country": "GB",
            "region": "HLD",
            "city": "Portree",
            "zipCode": "IV51"
          },
          {
            "country": "GB",
            "region": "ORK",
            "city": "Kirkwall",
            "zipCode": "KW15"
          },
          {
            "country": "GB",
            "region": "FIF",
            "city": "Newburgh",
            "zipCode": "KY14"
          },
          {
            "country": "GB",
            "region": "FIF",
            "city": "Strathkinness",
            "zipCode": "KY16"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L1"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L10"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L11"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L12"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L13"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L14"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Wavertree",
            "zipCode": "L15"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L16"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L17"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L18"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L19"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L2"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L20"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L21"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L22"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L23"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Speke",
            "zipCode": "L24"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Woolton",
            "zipCode": "L25"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L26"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L27"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L28"
          },
          {
            "country": "GB",
            "region": "SFT",
            "city": "Sefton",
            "zipCode": "L29"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L3"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L30"
          },
          {
            "country": "GB",
            "region": "SFT",
            "city": "Maghull",
            "zipCode": "L31"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L32"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L33"
          },
          {
            "country": "GB",
            "region": "KWL",
            "city": "Prescot",
            "zipCode": "L34"
          },
          {
            "country": "GB",
            "region": "SHN",
            "city": "Rainhill",
            "zipCode": "L35"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L36"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L37"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Ormskirk",
            "zipCode": "L39"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L4"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Rufford",
            "zipCode": "L40"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L5"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L6"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L69"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L7"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Liverpool",
            "zipCode": "L8"
          },
          {
            "country": "GB",
            "region": "LIV",
            "city": "Walton",
            "zipCode": "L9"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Morecambe",
            "zipCode": "LA1"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Sedbergh",
            "zipCode": "LA10"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Grange-over-Sands",
            "zipCode": "LA11"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Ulverston",
            "zipCode": "LA12"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Barrow in Furness",
            "zipCode": "LA13"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Barrow in Furness",
            "zipCode": "LA14"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Dalton in Furness",
            "zipCode": "LA15"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Ireleth",
            "zipCode": "LA16"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Kirkby-in-Furness",
            "zipCode": "LA17"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Lower Bentham",
            "zipCode": "LA2"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Coniston",
            "zipCode": "LA21"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Grasmere",
            "zipCode": "LA22"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Windermere",
            "zipCode": "LA23"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Morecambe",
            "zipCode": "LA3"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Morecambe",
            "zipCode": "LA4"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Carnforth",
            "zipCode": "LA5"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Kirkby Lonsdale",
            "zipCode": "LA6"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Milnthorpe",
            "zipCode": "LA7"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Endmoor",
            "zipCode": "LA8"
          },
          {
            "country": "GB",
            "region": "CMA",
            "city": "Kendal",
            "zipCode": "LA9"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Powys",
            "zipCode": "LD1"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Builth Wells",
            "zipCode": "LD2"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Groesffordd",
            "zipCode": "LD3"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Llangammarch Wells",
            "zipCode": "LD4"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Rhayader",
            "zipCode": "LD6"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Presteigne",
            "zipCode": "LD8"
          },
          {
            "country": "GB",
            "region": "LCE",
            "city": "Leicester",
            "zipCode": "LE1"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Wigston Parva",
            "zipCode": "LE10"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Loughborough",
            "zipCode": "LE11"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Sileby",
            "zipCode": "LE12"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Melton Mowbray",
            "zipCode": "LE13"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Burrough on the Hill",
            "zipCode": "LE14"
          },
          {
            "country": "GB",
            "region": "RUT",
            "city": "Whissendine",
            "zipCode": "LE15"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Weston by Welland",
            "zipCode": "LE16"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Leire",
            "zipCode": "LE17"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Wigston Parva",
            "zipCode": "LE18"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Littlethorpe",
            "zipCode": "LE19"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Oadby",
            "zipCode": "LE2"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Leicester Forest East",
            "zipCode": "LE3"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Thurmaston",
            "zipCode": "LE4"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Leicestershire",
            "zipCode": "LE5"
          },
          {
            "country": "GB",
            "region": "LCE",
            "city": "Leicester",
            "zipCode": "LE6"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Worthington",
            "zipCode": "LE65"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Ellistown",
            "zipCode": "LE67"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "East Goscote",
            "zipCode": "LE7"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Fleckney",
            "zipCode": "LE8"
          },
          {
            "country": "GB",
            "region": "LEC",
            "city": "Huncote",
            "zipCode": "LE9"
          },
          {
            "country": "GB",
            "region": "WRX",
            "city": "Gwersyllt",
            "zipCode": "LL11"
          },
          {
            "country": "GB",
            "region": "WRX",
            "city": "Wrexham",
            "zipCode": "LL12"
          },
          {
            "country": "GB",
            "region": "WRX",
            "city": "Wrexham",
            "zipCode": "LL13"
          },
          {
            "country": "GB",
            "region": "WRX",
            "city": "Wrexham",
            "zipCode": "LL14"
          },
          {
            "country": "GB",
            "region": "DEN",
            "city": "Ruthin",
            "zipCode": "LL15"
          },
          {
            "country": "GB",
            "region": "DEN",
            "city": "Henllan",
            "zipCode": "LL16"
          },
          {
            "country": "GB",
            "region": "DEN",
            "city": "Prestatyn",
            "zipCode": "LL19"
          },
          {
            "country": "GB",
            "region": "DEN",
            "city": "Llangollen",
            "zipCode": "LL20"
          },
          {
            "country": "GB",
            "region": "DEN",
            "city": "Llandrillo",
            "zipCode": "LL21"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Bala",
            "zipCode": "LL23"
          },
          {
            "country": "GB",
            "region": "CWY",
            "city": "Llanrwst",
            "zipCode": "LL24"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Aberdyfi",
            "zipCode": "LL35"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Tywyn",
            "zipCode": "LL36"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Llwyngwril",
            "zipCode": "LL37"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Fairbourne",
            "zipCode": "LL38"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Gwynedd",
            "zipCode": "LL40"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Harlech",
            "zipCode": "LL46"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Penrhyndeudraeth",
            "zipCode": "LL48"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Porthmadog",
            "zipCode": "LL49"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Garndolbenmaen",
            "zipCode": "LL51"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Porthmadog",
            "zipCode": "LL52"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Pwllheli",
            "zipCode": "LL53"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Caernarfon",
            "zipCode": "LL54"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Llanberis",
            "zipCode": "LL55"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Y Felinheli",
            "zipCode": "LL56"
          },
          {
            "country": "GB",
            "region": "GWN",
            "city": "Bangor",
            "zipCode": "LL57"
          },
          {
            "country": "GB",
            "region": "AGY",
            "city": "Beaumaris",
            "zipCode": "LL58"
          },
          {
            "country": "GB",
            "region": "AGY",
            "city": "Menai Bridge",
            "zipCode": "LL59"
          },
          {
            "country": "GB",
            "region": "AGY",
            "city": "Gaerwen",
            "zipCode": "LL60"
          },
          {
            "country": "GB",
            "region": "AGY",
            "city": "Valley",
            "zipCode": "LL65"
          },
          {
            "country": "GB",
            "region": "AGY",
            "city": "Amlwch",
            "zipCode": "LL68"
          },
          {
            "country": "GB",
            "region": "AGY",
            "city": "Benllech",
            "zipCode": "LL74"
          },
          {
            "country": "GB",
            "region": "AGY",
            "city": "Capel Coch",
            "zipCode": "LL77"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Saxilby",
            "zipCode": "LN1"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Woodhall Spa",
            "zipCode": "LN10"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Mumby",
            "zipCode": "LN13"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Welton",
            "zipCode": "LN2"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Lincoln",
            "zipCode": "LN3"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Washingborough",
            "zipCode": "LN4"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Lincoln",
            "zipCode": "LN5"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Witham St Hughs",
            "zipCode": "LN6"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "North Kelsey",
            "zipCode": "LN7"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Middle Rasen",
            "zipCode": "LN8"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Horncastle",
            "zipCode": "LN9"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS1"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS10"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS11"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS12"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Rodley",
            "zipCode": "LS13"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS14"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS15"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS16"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Weeton",
            "zipCode": "LS17"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS18"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Yeadon",
            "zipCode": "LS19"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS2"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS20"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Pool",
            "zipCode": "LS21"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Wetherby",
            "zipCode": "LS22"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Wetherby",
            "zipCode": "LS23"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Tadcaster",
            "zipCode": "LS24"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Sherburn in Elmet",
            "zipCode": "LS25"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Woodlesford",
            "zipCode": "LS26"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Morley",
            "zipCode": "LS27"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Stanningley",
            "zipCode": "LS28"
          },
          {
            "country": "GB",
            "region": "BRD",
            "city": "Ilkley",
            "zipCode": "LS29"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS3"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS4"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS5"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS6"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS7"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS8"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "LS9"
          },
          {
            "country": "GB",
            "region": "CBF",
            "city": "Eggington",
            "zipCode": "LU7"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M1"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M11"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M12"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M13"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M14"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M15"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M16"
          },
          {
            "country": "GB",
            "region": "TRF",
            "city": "Trafford Park",
            "zipCode": "M17"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Openshaw",
            "zipCode": "M18"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M19"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M2"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M20"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M21"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Wythenshawe",
            "zipCode": "M22"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Wythenshawe",
            "zipCode": "M23"
          },
          {
            "country": "GB",
            "region": "RCH",
            "city": "Middleton",
            "zipCode": "M24"
          },
          {
            "country": "GB",
            "region": "BUR",
            "city": "Prestwich",
            "zipCode": "M25"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M26"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Clifton",
            "zipCode": "M27"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Worsley",
            "zipCode": "M28"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Tyldesley",
            "zipCode": "M29"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Salford",
            "zipCode": "M3"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M30"
          },
          {
            "country": "GB",
            "region": "TRF",
            "city": "Partington",
            "zipCode": "M31"
          },
          {
            "country": "GB",
            "region": "TRF",
            "city": "Stretford",
            "zipCode": "M32"
          },
          {
            "country": "GB",
            "region": "TRF",
            "city": "Sale",
            "zipCode": "M33"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M34"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M35"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M38"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Salford",
            "zipCode": "M4"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M40"
          },
          {
            "country": "GB",
            "region": "TRF",
            "city": "Urmston",
            "zipCode": "M41"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M43"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M44"
          },
          {
            "country": "GB",
            "region": "BUR",
            "city": "Whitefield",
            "zipCode": "M45"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M46"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Salford",
            "zipCode": "M5"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Salford",
            "zipCode": "M50"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Salford",
            "zipCode": "M6"
          },
          {
            "country": "GB",
            "region": "SLF",
            "city": "Salford",
            "zipCode": "M7"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M8"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M9"
          },
          {
            "country": "GB",
            "region": "MAN",
            "city": "Manchester",
            "zipCode": "M90"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK1"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes Village",
            "zipCode": "MK10"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Stony Stratford",
            "zipCode": "MK11"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Wolverton",
            "zipCode": "MK12"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK13"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK14"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK15"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Stoke Goldington",
            "zipCode": "MK16"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Woburn Sands",
            "zipCode": "MK17"
          },
          {
            "country": "GB",
            "region": "BKM",
            "city": "Calvert",
            "zipCode": "MK18"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK19"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK2"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK3"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK4"
          },
          {
            "country": "GB",
            "region": "BDF",
            "city": "Great Denham",
            "zipCode": "MK40"
          },
          {
            "country": "GB",
            "region": "BDF",
            "city": "Bedford",
            "zipCode": "MK41"
          },
          {
            "country": "GB",
            "region": "BDF",
            "city": "Wixams",
            "zipCode": "MK42"
          },
          {
            "country": "GB",
            "region": "CBF",
            "city": "Brogborough",
            "zipCode": "MK43"
          },
          {
            "country": "GB",
            "region": "CBF",
            "city": "Moggerhanger",
            "zipCode": "MK44"
          },
          {
            "country": "GB",
            "region": "CBF",
            "city": "Upper Gravenhurst",
            "zipCode": "MK45"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Warrington",
            "zipCode": "MK46"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK5"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK6"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK7"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Crownhill",
            "zipCode": "MK8"
          },
          {
            "country": "GB",
            "region": "MIK",
            "city": "Milton Keynes",
            "zipCode": "MK9"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE1"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Gateshead",
            "zipCode": "NE10"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Kibblesworth",
            "zipCode": "NE11"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE12"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE13"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE15"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Sunniside",
            "zipCode": "NE16"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE17"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Otterburn",
            "zipCode": "NE19"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE2"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Ponteland",
            "zipCode": "NE20"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Blaydon",
            "zipCode": "NE21"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Bedlington",
            "zipCode": "NE22"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Cramlington",
            "zipCode": "NE23"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Blyth",
            "zipCode": "NE24"
          },
          {
            "country": "GB",
            "region": "NTY",
            "city": "Whitley Bay",
            "zipCode": "NE25"
          },
          {
            "country": "GB",
            "region": "NTY",
            "city": "Whitley Bay",
            "zipCode": "NE26"
          },
          {
            "country": "GB",
            "region": "NTY",
            "city": "New York",
            "zipCode": "NE27"
          },
          {
            "country": "GB",
            "region": "NTY",
            "city": "Wallsend",
            "zipCode": "NE28"
          },
          {
            "country": "GB",
            "region": "NTY",
            "city": "Whitley Bay",
            "zipCode": "NE29"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE3"
          },
          {
            "country": "GB",
            "region": "NTY",
            "city": "North Shields",
            "zipCode": "NE30"
          },
          {
            "country": "GB",
            "region": "STY",
            "city": "Jarrow",
            "zipCode": "NE31"
          },
          {
            "country": "GB",
            "region": "STY",
            "city": "South Shields",
            "zipCode": "NE32"
          },
          {
            "country": "GB",
            "region": "STY",
            "city": "South Shields",
            "zipCode": "NE34"
          },
          {
            "country": "GB",
            "region": "STY",
            "city": "East Boldon",
            "zipCode": "NE35"
          },
          {
            "country": "GB",
            "region": "STY",
            "city": "West Boldon",
            "zipCode": "NE36"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Washington",
            "zipCode": "NE37"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Washington",
            "zipCode": "NE38"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Rowlands Gill",
            "zipCode": "NE39"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE4"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Crawcrook",
            "zipCode": "NE40"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Wylam",
            "zipCode": "NE41"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Prudhoe",
            "zipCode": "NE42"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Stocksfield",
            "zipCode": "NE43"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Riding Mill",
            "zipCode": "NE44"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Corbridge",
            "zipCode": "NE45"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Hexham",
            "zipCode": "NE46"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Hexham",
            "zipCode": "NE47"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Hexham",
            "zipCode": "NE48"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Haltwhistle",
            "zipCode": "NE49"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE5"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE6"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Widdrington",
            "zipCode": "NE61"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Choppington",
            "zipCode": "NE62"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Ashington",
            "zipCode": "NE63"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Newbiggin-by-the-Sea",
            "zipCode": "NE64"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Warkworth",
            "zipCode": "NE65"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Alnwick",
            "zipCode": "NE66"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Seahouses",
            "zipCode": "NE68"
          },
          {
            "country": "GB",
            "region": "NET",
            "city": "Newcastle upon Tyne",
            "zipCode": "NE7"
          },
          {
            "country": "GB",
            "region": "NBL",
            "city": "Belford",
            "zipCode": "NE70"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Gateshead",
            "zipCode": "NE8"
          },
          {
            "country": "GB",
            "region": "GAT",
            "city": "Gateshead",
            "zipCode": "NE9"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Evedon",
            "zipCode": "NG34"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Northampton",
            "zipCode": "NN1"
          },
          {
            "country": "GB",
            "region": "BDF",
            "city": "Wymington",
            "zipCode": "NN10"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Woodford Halse",
            "zipCode": "NN11"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Yardley Gobion",
            "zipCode": "NN12"
          },
          {
            "country": "GB",
            "region": "BKM",
            "city": "Turweston",
            "zipCode": "NN13"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Woodford",
            "zipCode": "NN14"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Kettering",
            "zipCode": "NN15"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Kettering",
            "zipCode": "NN16"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Gretton",
            "zipCode": "NN17"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Corby",
            "zipCode": "NN18"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Northampton",
            "zipCode": "NN2"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Wollaston",
            "zipCode": "NN29"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Northamptonshire",
            "zipCode": "NN3"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Wootton",
            "zipCode": "NN4"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Northampton",
            "zipCode": "NN5"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Long Buckby Wharf",
            "zipCode": "NN6"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Quinton",
            "zipCode": "NN7"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Wilby",
            "zipCode": "NN8"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Wellingborough",
            "zipCode": "NN9"
          },
          {
            "country": "GB",
            "region": "NWP",
            "city": "Rogerstone",
            "zipCode": "NP10"
          },
          {
            "country": "GB",
            "region": "CAY",
            "city": "Trinant",
            "zipCode": "NP11"
          },
          {
            "country": "GB",
            "region": "CAY",
            "city": "Blackwood",
            "zipCode": "NP12"
          },
          {
            "country": "GB",
            "region": "BGW",
            "city": "Blaina",
            "zipCode": "NP13"
          },
          {
            "country": "GB",
            "region": "MON",
            "city": "Usk",
            "zipCode": "NP15"
          },
          {
            "country": "GB",
            "region": "MON",
            "city": "Chepstow",
            "zipCode": "NP16"
          },
          {
            "country": "GB",
            "region": "NWP",
            "city": "Newport",
            "zipCode": "NP18"
          },
          {
            "country": "GB",
            "region": "NWP",
            "city": "Newport",
            "zipCode": "NP19"
          },
          {
            "country": "GB",
            "region": "NWP",
            "city": "Newport",
            "zipCode": "NP20"
          },
          {
            "country": "GB",
            "region": "CAY",
            "city": "Abertysswg",
            "zipCode": "NP22"
          },
          {
            "country": "GB",
            "region": "BGW",
            "city": "Nantyglo",
            "zipCode": "NP23"
          },
          {
            "country": "GB",
            "region": "MON",
            "city": "Monmouth",
            "zipCode": "NP25"
          },
          {
            "country": "GB",
            "region": "MON",
            "city": "Portskewett",
            "zipCode": "NP26"
          },
          {
            "country": "GB",
            "region": "MON",
            "city": "Penperlleni",
            "zipCode": "NP4"
          },
          {
            "country": "GB",
            "region": "TOF",
            "city": "Llantarnam",
            "zipCode": "NP44"
          },
          {
            "country": "GB",
            "region": "MON",
            "city": "Abergavenny",
            "zipCode": "NP7"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Crickhowell",
            "zipCode": "NP8"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR1"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Stratton Strawless",
            "zipCode": "NR10"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Banningham",
            "zipCode": "NR11"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Sutton",
            "zipCode": "NR12"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Moulton St Mary",
            "zipCode": "NR13"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Trowse Newton",
            "zipCode": "NR14"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR15"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Tacolneston",
            "zipCode": "NR16"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Little Ellingham",
            "zipCode": "NR17"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Wymondham",
            "zipCode": "NR18"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Westfield",
            "zipCode": "NR19"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR2"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Swanton Morley",
            "zipCode": "NR20"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "South Creake",
            "zipCode": "NR21"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Wells-next-the-Sea",
            "zipCode": "NR23"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Melton Constable",
            "zipCode": "NR24"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Kelling",
            "zipCode": "NR25"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Upper Sheringham",
            "zipCode": "NR26"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Overstrand",
            "zipCode": "NR27"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "North Walsham",
            "zipCode": "NR28"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Potter Heigham",
            "zipCode": "NR29"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR3"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Great Yarmouth",
            "zipCode": "NR30"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Bradwell",
            "zipCode": "NR31"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Lowestoft",
            "zipCode": "NR32"
          },
          {
            "country": "GB",
            "region": "SFK",
            "city": "Lowestoft",
            "zipCode": "NR33"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Toft Monks",
            "zipCode": "NR34"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Woodton",
            "zipCode": "NR35"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR4"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR5"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR6"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Sprowston",
            "zipCode": "NR7"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR8"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Norwich",
            "zipCode": "NR9"
          },
          {
            "country": "GB",
            "region": "OLD",
            "city": "Oldham",
            "zipCode": "OL1"
          },
          {
            "country": "GB",
            "region": "RCH",
            "city": "Rochdale",
            "zipCode": "OL10"
          },
          {
            "country": "GB",
            "region": "RCH",
            "city": "Rochdale",
            "zipCode": "OL11"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Whitworth",
            "zipCode": "OL12"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Bacup",
            "zipCode": "OL13"
          },
          {
            "country": "GB",
            "region": "CLD",
            "city": "Todmorden",
            "zipCode": "OL14"
          },
          {
            "country": "GB",
            "region": "RCH",
            "city": "Littleborough",
            "zipCode": "OL15"
          },
          {
            "country": "GB",
            "region": "RCH",
            "city": "Rochdale",
            "zipCode": "OL16"
          },
          {
            "country": "GB",
            "region": "OLD",
            "city": "Royton",
            "zipCode": "OL2"
          },
          {
            "country": "GB",
            "region": "OLD",
            "city": "Dobcross",
            "zipCode": "OL3"
          },
          {
            "country": "GB",
            "region": "OLD",
            "city": "Springhead",
            "zipCode": "OL4"
          },
          {
            "country": "GB",
            "region": "TAM",
            "city": "Mossley",
            "zipCode": "OL5"
          },
          {
            "country": "GB",
            "region": "TAM",
            "city": "Ashton-under-Lyne",
            "zipCode": "OL6"
          },
          {
            "country": "GB",
            "region": "TAM",
            "city": "Ashton-under-Lyne",
            "zipCode": "OL7"
          },
          {
            "country": "GB",
            "region": "OLD",
            "city": "Oldham",
            "zipCode": "OL8"
          },
          {
            "country": "GB",
            "region": "OLD",
            "city": "Chadderton",
            "zipCode": "OL9"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Oxford",
            "zipCode": "OX1"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Berinsfield",
            "zipCode": "OX10"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Aston Upthorpe",
            "zipCode": "OX11"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "East Hendred",
            "zipCode": "OX12"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Tubney",
            "zipCode": "OX13"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Sutton Courtenay",
            "zipCode": "OX14"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "North Newington",
            "zipCode": "OX15"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Banbury",
            "zipCode": "OX16"
          },
          {
            "country": "GB",
            "region": "NTH",
            "city": "Middleton Cheney",
            "zipCode": "OX17"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Radcot",
            "zipCode": "OX18"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Farmoor",
            "zipCode": "OX2"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Woodstock",
            "zipCode": "OX20"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Upper Heyford",
            "zipCode": "OX25"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Oxford",
            "zipCode": "OX26"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Souldern",
            "zipCode": "OX27"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Witney",
            "zipCode": "OX28"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Long Hanborough",
            "zipCode": "OX29"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Oxford",
            "zipCode": "OX3"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Wheatley",
            "zipCode": "OX33"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Oxford",
            "zipCode": "OX4"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Stadhampton",
            "zipCode": "OX44"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Watlington",
            "zipCode": "OX49"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Tackley",
            "zipCode": "OX5"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Middle Barton",
            "zipCode": "OX7"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Spalding",
            "zipCode": "PE11"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Sutton Bridge",
            "zipCode": "PE12"
          },
          {
            "country": "GB",
            "region": "CAM",
            "city": "Wisbech",
            "zipCode": "PE13"
          },
          {
            "country": "GB",
            "region": "CAM",
            "city": "Wisbech",
            "zipCode": "PE14"
          },
          {
            "country": "GB",
            "region": "CAM",
            "city": "Benwick",
            "zipCode": "PE15"
          },
          {
            "country": "GB",
            "region": "CAM",
            "city": "Saint Neots",
            "zipCode": "PE19"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Swineshead",
            "zipCode": "PE20"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Boston",
            "zipCode": "PE21"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Wrangle",
            "zipCode": "PE22"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Spilsby",
            "zipCode": "PE23"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Wainfleet All Saints",
            "zipCode": "PE24"
          },
          {
            "country": "GB",
            "region": "LIN",
            "city": "Skegness",
            "zipCode": "PE25"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Kings Lynn",
            "zipCode": "PE30"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Burnham Overy Staithe",
            "zipCode": "PE31"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Gayton",
            "zipCode": "PE32"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "West Winch",
            "zipCode": "PE33"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Terrington St Clement",
            "zipCode": "PE34"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Sandringham",
            "zipCode": "PE35"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Hunstanton",
            "zipCode": "PE36"
          },
          {
            "country": "GB",
            "region": "NFK",
            "city": "Swaffham",
            "zipCode": "PE37"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Perth",
            "zipCode": "PH1"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Blairgowrie",
            "zipCode": "PH10"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Blairgowrie",
            "zipCode": "PH11"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Meigle",
            "zipCode": "PH12"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Blairgowrie",
            "zipCode": "PH13"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Inchture",
            "zipCode": "PH14"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Perth",
            "zipCode": "PH2"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Aberuthven",
            "zipCode": "PH3"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Crieff",
            "zipCode": "PH6"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Crieff",
            "zipCode": "PH7"
          },
          {
            "country": "GB",
            "region": "PKN",
            "city": "Dunkeld",
            "zipCode": "PH8"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Launceston",
            "zipCode": "PL15"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Lifton",
            "zipCode": "PL16"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Lostwithiel",
            "zipCode": "PL22"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Saint Blazey",
            "zipCode": "PL24"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "St Austell",
            "zipCode": "PL25"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "St Austell",
            "zipCode": "PL26"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Wadebridge",
            "zipCode": "PL27"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Cornwall",
            "zipCode": "PL30"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Bodmin",
            "zipCode": "PL31"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Camelford",
            "zipCode": "PL32"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Delabole",
            "zipCode": "PL33"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Tintagel",
            "zipCode": "PL34"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Boscastle",
            "zipCode": "PL35"
          },
          {
            "country": "GB",
            "region": "SFT",
            "city": "Southport",
            "zipCode": "PR8"
          },
          {
            "country": "GB",
            "region": "SFT",
            "city": "Southport",
            "zipCode": "PR9"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Whitchurch",
            "zipCode": "RG28"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Hope",
            "zipCode": "S33"
          },
          {
            "country": "GB",
            "region": "SWA",
            "city": "Swansea",
            "zipCode": "SA1"
          },
          {
            "country": "GB",
            "region": "NTL",
            "city": "Neath",
            "zipCode": "SA10"
          },
          {
            "country": "GB",
            "region": "NTL",
            "city": "Cwmgwrach",
            "zipCode": "SA11"
          },
          {
            "country": "GB",
            "region": "NTL",
            "city": "Port Talbot",
            "zipCode": "SA12"
          },
          {
            "country": "GB",
            "region": "NTL",
            "city": "Port Talbot",
            "zipCode": "SA13"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Llanelli",
            "zipCode": "SA14"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Pontyates",
            "zipCode": "SA15"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Burry Port",
            "zipCode": "SA16"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Kidwelly",
            "zipCode": "SA17"
          },
          {
            "country": "GB",
            "region": "NTL",
            "city": "Gwaun-Cae-Gurwen",
            "zipCode": "SA18"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Llanwrda",
            "zipCode": "SA19"
          },
          {
            "country": "GB",
            "region": "SWA",
            "city": "Swansea",
            "zipCode": "SA2"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Llandovery",
            "zipCode": "SA20"
          },
          {
            "country": "GB",
            "region": "SWA",
            "city": "Swansea",
            "zipCode": "SA3"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Llangunnor",
            "zipCode": "SA31"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Carmarthenshire",
            "zipCode": "SA32"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Carmarthen",
            "zipCode": "SA33"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Whitland",
            "zipCode": "SA34"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Llanfyrnach",
            "zipCode": "SA35"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Glogue",
            "zipCode": "SA36"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Newcastle Emlyn",
            "zipCode": "SA38"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Pencader",
            "zipCode": "SA39"
          },
          {
            "country": "GB",
            "region": "SWA",
            "city": "Swansea",
            "zipCode": "SA4"
          },
          {
            "country": "GB",
            "region": "CMN",
            "city": "Llanybydder",
            "zipCode": "SA40"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Crymych",
            "zipCode": "SA41"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Newport",
            "zipCode": "SA42"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Cardigan",
            "zipCode": "SA43"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Caerwedros",
            "zipCode": "SA44"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Aberaeron",
            "zipCode": "SA46"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Lampeter",
            "zipCode": "SA48"
          },
          {
            "country": "GB",
            "region": "SWA",
            "city": "Swansea",
            "zipCode": "SA5"
          },
          {
            "country": "GB",
            "region": "SWA",
            "city": "Swansea",
            "zipCode": "SA6"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Haverfordwest",
            "zipCode": "SA61"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "St Davids",
            "zipCode": "SA62"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Clarbeston Road",
            "zipCode": "SA63"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Goodwick",
            "zipCode": "SA64"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Fishguard",
            "zipCode": "SA65"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Rosebush",
            "zipCode": "SA66"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Narberth",
            "zipCode": "SA67"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Kilgetty",
            "zipCode": "SA68"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Saundersfoot",
            "zipCode": "SA69"
          },
          {
            "country": "GB",
            "region": "SWA",
            "city": "Swansea",
            "zipCode": "SA7"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Tenby",
            "zipCode": "SA70"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Pembroke Dock",
            "zipCode": "SA71"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Pembroke Dock",
            "zipCode": "SA72"
          },
          {
            "country": "GB",
            "region": "PEM",
            "city": "Neyland",
            "zipCode": "SA73"
          },
          {
            "country": "GB",
            "region": "NTL",
            "city": "Trebanos",
            "zipCode": "SA8"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Gurnos",
            "zipCode": "SA9"
          },
          {
            "country": "GB",
            "region": "CBF",
            "city": "Northill",
            "zipCode": "SG18"
          },
          {
            "country": "GB",
            "region": "CAM",
            "city": "Waresley",
            "zipCode": "SG19"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Stockport",
            "zipCode": "SK1"
          },
          {
            "country": "GB",
            "region": "CHE",
            "city": "Rainow",
            "zipCode": "SK10"
          },
          {
            "country": "GB",
            "region": "CHE",
            "city": "Macclesfield",
            "zipCode": "SK11"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Stockport",
            "zipCode": "SK12"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Hadfield",
            "zipCode": "SK13"
          },
          {
            "country": "GB",
            "region": "TAM",
            "city": "Mottram in Longdendale",
            "zipCode": "SK14"
          },
          {
            "country": "GB",
            "region": "TAM",
            "city": "Stalybridge",
            "zipCode": "SK15"
          },
          {
            "country": "GB",
            "region": "TAM",
            "city": "Hyde",
            "zipCode": "SK16"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Taddington",
            "zipCode": "SK17"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Stockport",
            "zipCode": "SK2"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "New Mills",
            "zipCode": "SK22"
          },
          {
            "country": "GB",
            "region": "DBY",
            "city": "Combs",
            "zipCode": "SK23"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Stockport",
            "zipCode": "SK3"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Heaton Moor",
            "zipCode": "SK4"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Stockport",
            "zipCode": "SK5"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "High Lane",
            "zipCode": "SK6"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Woodford",
            "zipCode": "SK7"
          },
          {
            "country": "GB",
            "region": "SKP",
            "city": "Gatley",
            "zipCode": "SK8"
          },
          {
            "country": "GB",
            "region": "CHE",
            "city": "Wilmslow",
            "zipCode": "SK9"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Wiltshire",
            "zipCode": "SN10"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Heddington",
            "zipCode": "SN11"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Bowerhill",
            "zipCode": "SN12"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Corsham",
            "zipCode": "SN13"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "West Kington",
            "zipCode": "SN14"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Sutton Benger",
            "zipCode": "SN15"
          },
          {
            "country": "GB",
            "region": "SWD",
            "city": "Swindon",
            "zipCode": "SN3"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Hook",
            "zipCode": "SN4"
          },
          {
            "country": "GB",
            "region": "SWD",
            "city": "Swindon",
            "zipCode": "SN5"
          },
          {
            "country": "GB",
            "region": "OXF",
            "city": "Longcot",
            "zipCode": "SN7"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Mildenhall",
            "zipCode": "SN8"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Easton Royal",
            "zipCode": "SN9"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO14"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO15"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO16"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO17"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO18"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO19"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Stockbridge",
            "zipCode": "SO20"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "South Wonston",
            "zipCode": "SO21"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Winchester",
            "zipCode": "SO22"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Winchester",
            "zipCode": "SO23"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Old Alresford",
            "zipCode": "SO24"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO30"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Burridge",
            "zipCode": "SO31"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Waltham Chase",
            "zipCode": "SO32"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Totton",
            "zipCode": "SO40"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Milford on Sea",
            "zipCode": "SO41"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "East Boldre",
            "zipCode": "SO42"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Lyndhurst",
            "zipCode": "SO43"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO45"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Brambridge",
            "zipCode": "SO50"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Lockerley",
            "zipCode": "SO51"
          },
          {
            "country": "GB",
            "region": "STH",
            "city": "Southampton",
            "zipCode": "SO52"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Eastleigh",
            "zipCode": "SO53"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Salisbury",
            "zipCode": "SP1"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Charlton",
            "zipCode": "SP10"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Perham Down",
            "zipCode": "SP11"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Wilton",
            "zipCode": "SP2"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Tisbury",
            "zipCode": "SP3"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Bulford Camp",
            "zipCode": "SP4"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Bowerchalke",
            "zipCode": "SP5"
          },
          {
            "country": "GB",
            "region": "HAM",
            "city": "Fordingbridge",
            "zipCode": "SP6"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Shaftesbury",
            "zipCode": "SP7"
          },
          {
            "country": "GB",
            "region": "DOR",
            "city": "Gillingham",
            "zipCode": "SP8"
          },
          {
            "country": "GB",
            "region": "WIL",
            "city": "Tidworth",
            "zipCode": "SP9"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Sunderland",
            "zipCode": "SR1"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Sunderland",
            "zipCode": "SR2"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Sunderland",
            "zipCode": "SR3"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Sunderland",
            "zipCode": "SR4"
          },
          {
            "country": "GB",
            "region": "SND",
            "city": "Sunderland",
            "zipCode": "SR5"
          },
          {
            "country": "GB",
            "region": "STY",
            "city": "Cleadon",
            "zipCode": "SR6"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Seaham",
            "zipCode": "SR7"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Peterlee",
            "zipCode": "SR8"
          },
          {
            "country": "GB",
            "region": "SHR",
            "city": "Selattyn",
            "zipCode": "SY10"
          },
          {
            "country": "GB",
            "region": "SHR",
            "city": "St Martins",
            "zipCode": "SY11"
          },
          {
            "country": "GB",
            "region": "SHR",
            "city": "Ellesmere",
            "zipCode": "SY12"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Machynlleth",
            "zipCode": "SY20"
          },
          {
            "country": "GB",
            "region": "POW",
            "city": "Llanymynech",
            "zipCode": "SY22"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Llan-non",
            "zipCode": "SY23"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Talybont",
            "zipCode": "SY24"
          },
          {
            "country": "GB",
            "region": "CGN",
            "city": "Tregaron",
            "zipCode": "SY25"
          },
          {
            "country": "GB",
            "region": "SHR",
            "city": "Ludlow",
            "zipCode": "SY8"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Taunton",
            "zipCode": "TA1"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Upton",
            "zipCode": "TA10"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Keinton Mandeville",
            "zipCode": "TA11"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Martock",
            "zipCode": "TA12"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "South Petherton",
            "zipCode": "TA13"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Stoke-sub-Hamdon",
            "zipCode": "TA14"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Montacute",
            "zipCode": "TA15"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Merriott",
            "zipCode": "TA16"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Hinton St George",
            "zipCode": "TA17"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Crewkerne",
            "zipCode": "TA18"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Horton",
            "zipCode": "TA19"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Taunton",
            "zipCode": "TA2"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Chard",
            "zipCode": "TA20"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Wellington",
            "zipCode": "TA21"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Dulverton",
            "zipCode": "TA22"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Watchet",
            "zipCode": "TA23"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Minehead",
            "zipCode": "TA24"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Taunton",
            "zipCode": "TA3"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Wiveliscombe",
            "zipCode": "TA4"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Holford",
            "zipCode": "TA5"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "North Petherton",
            "zipCode": "TA6"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Stawell",
            "zipCode": "TA7"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Burnham-on-Sea",
            "zipCode": "TA8"
          },
          {
            "country": "GB",
            "region": "SOM",
            "city": "Mark",
            "zipCode": "TA9"
          },
          {
            "country": "GB",
            "region": "TOB",
            "city": "Torquay",
            "zipCode": "TQ1"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Buckfastleigh",
            "zipCode": "TQ11"
          },
          {
            "country": "GB",
            "region": "TOB",
            "city": "Torquay",
            "zipCode": "TQ12"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Newton Abbot",
            "zipCode": "TQ13"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Teignmouth",
            "zipCode": "TQ14"
          },
          {
            "country": "GB",
            "region": "TOB",
            "city": "Torquay",
            "zipCode": "TQ2"
          },
          {
            "country": "GB",
            "region": "TOB",
            "city": "Paignton",
            "zipCode": "TQ3"
          },
          {
            "country": "GB",
            "region": "TOB",
            "city": "Paignton",
            "zipCode": "TQ4"
          },
          {
            "country": "GB",
            "region": "TOB",
            "city": "Brixham",
            "zipCode": "TQ5"
          },
          {
            "country": "GB",
            "region": "DEV",
            "city": "Totnes",
            "zipCode": "TQ9"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Truro",
            "zipCode": "TR1"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Penryn",
            "zipCode": "TR10"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Flushing",
            "zipCode": "TR11"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Manaccan",
            "zipCode": "TR12"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Ashton",
            "zipCode": "TR13"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Camborne",
            "zipCode": "TR14"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Redruth",
            "zipCode": "TR15"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Redruth",
            "zipCode": "TR16"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Marazion",
            "zipCode": "TR17"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Penzance",
            "zipCode": "TR18"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Penzance",
            "zipCode": "TR19"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Truro",
            "zipCode": "TR2"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Goldsithney",
            "zipCode": "TR20"
          },
          {
            "country": "GB",
            "region": "IOS",
            "city": "Isles of Scilly",
            "zipCode": "TR21"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "St Ives",
            "zipCode": "TR26"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Leedstown",
            "zipCode": "TR27"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Carnon Downs",
            "zipCode": "TR3"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Threemilestone",
            "zipCode": "TR4"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Bolingey",
            "zipCode": "TR6"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Newquay",
            "zipCode": "TR7"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Newquay",
            "zipCode": "TR8"
          },
          {
            "country": "GB",
            "region": "CON",
            "city": "Indian Queens",
            "zipCode": "TR9"
          },
          {
            "country": "GB",
            "region": "MDB",
            "city": "Middlesbrough",
            "zipCode": "TS1"
          },
          {
            "country": "GB",
            "region": "RCC",
            "city": "Redcar",
            "zipCode": "TS10"
          },
          {
            "country": "GB",
            "region": "RCC",
            "city": "Redcar",
            "zipCode": "TS11"
          },
          {
            "country": "GB",
            "region": "RCC",
            "city": "Skelton",
            "zipCode": "TS12"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Staithes",
            "zipCode": "TS13"
          },
          {
            "country": "GB",
            "region": "RCC",
            "city": "Guisborough",
            "zipCode": "TS14"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Yarm",
            "zipCode": "TS15"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Stockton-on-Tees",
            "zipCode": "TS16"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Thornaby",
            "zipCode": "TS17"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Stockton-on-Tees",
            "zipCode": "TS18"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Stockton-on-Tees",
            "zipCode": "TS19"
          },
          {
            "country": "GB",
            "region": "MDB",
            "city": "Middlesbrough",
            "zipCode": "TS2"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Stockton-on-Tees",
            "zipCode": "TS20"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Stockton-on-Tees",
            "zipCode": "TS21"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Billingham",
            "zipCode": "TS22"
          },
          {
            "country": "GB",
            "region": "STT",
            "city": "Billingham",
            "zipCode": "TS23"
          },
          {
            "country": "GB",
            "region": "HPL",
            "city": "Hartlepool",
            "zipCode": "TS24"
          },
          {
            "country": "GB",
            "region": "HPL",
            "city": "Hartlepool",
            "zipCode": "TS25"
          },
          {
            "country": "GB",
            "region": "HPL",
            "city": "Hartlepool",
            "zipCode": "TS26"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Blackhall Rocks",
            "zipCode": "TS27"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Station Town",
            "zipCode": "TS28"
          },
          {
            "country": "GB",
            "region": "DUR",
            "city": "Trimdon Grange",
            "zipCode": "TS29"
          },
          {
            "country": "GB",
            "region": "MDB",
            "city": "Middlesbrough",
            "zipCode": "TS3"
          },
          {
            "country": "GB",
            "region": "MDB",
            "city": "Middlesbrough",
            "zipCode": "TS4"
          },
          {
            "country": "GB",
            "region": "MDB",
            "city": "Middlesbrough",
            "zipCode": "TS5"
          },
          {
            "country": "GB",
            "region": "MDB",
            "city": "Middlesbrough",
            "zipCode": "TS6"
          },
          {
            "country": "GB",
            "region": "MDB",
            "city": "Nunthorpe",
            "zipCode": "TS7"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Newby",
            "zipCode": "TS8"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Stokesley",
            "zipCode": "TS9"
          },
          {
            "country": "GB",
            "region": "SHN",
            "city": "St Helens",
            "zipCode": "WA10"
          },
          {
            "country": "GB",
            "region": "SHN",
            "city": "St Helens",
            "zipCode": "WA11"
          },
          {
            "country": "GB",
            "region": "SHN",
            "city": "Newton-le-Willows",
            "zipCode": "WA12"
          },
          {
            "country": "GB",
            "region": "WRT",
            "city": "Lymm",
            "zipCode": "WA13"
          },
          {
            "country": "GB",
            "region": "TRF",
            "city": "Bowdon",
            "zipCode": "WA14"
          },
          {
            "country": "GB",
            "region": "TRF",
            "city": "Hale Barns",
            "zipCode": "WA15"
          },
          {
            "country": "GB",
            "region": "CHE",
            "city": "Mobberley",
            "zipCode": "WA16"
          },
          {
            "country": "GB",
            "region": "WRT",
            "city": "Warrington",
            "zipCode": "WA2"
          },
          {
            "country": "GB",
            "region": "WRT",
            "city": "Warrington",
            "zipCode": "WA3"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Cheshire West and Chester",
            "zipCode": "WA4"
          },
          {
            "country": "GB",
            "region": "HAL",
            "city": "Widnes",
            "zipCode": "WA5"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Helsby",
            "zipCode": "WA6"
          },
          {
            "country": "GB",
            "region": "CHW",
            "city": "Sutton Weaver",
            "zipCode": "WA7"
          },
          {
            "country": "GB",
            "region": "HAL",
            "city": "Widnes",
            "zipCode": "WA8"
          },
          {
            "country": "GB",
            "region": "SHN",
            "city": "St Helens",
            "zipCode": "WA9"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Wakefield",
            "zipCode": "WF1"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Castleford",
            "zipCode": "WF10"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Knottingley",
            "zipCode": "WF11"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Dewsbury",
            "zipCode": "WF12"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Dewsbury",
            "zipCode": "WF13"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Mirfield",
            "zipCode": "WF14"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Liversedge",
            "zipCode": "WF15"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Heckmondwike",
            "zipCode": "WF16"
          },
          {
            "country": "GB",
            "region": "LDS",
            "city": "Leeds",
            "zipCode": "WF17"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Wakefield",
            "zipCode": "WF2"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Wakefield",
            "zipCode": "WF3"
          },
          {
            "country": "GB",
            "region": "KIR",
            "city": "Grange Moor",
            "zipCode": "WF4"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Wakefield",
            "zipCode": "WF5"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Wakefield",
            "zipCode": "WF6"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Pontefract",
            "zipCode": "WF7"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "Pontefract",
            "zipCode": "WF8"
          },
          {
            "country": "GB",
            "region": "WKF",
            "city": "South Kirkby",
            "zipCode": "WF9"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Wigan",
            "zipCode": "WN1"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Hindley Green",
            "zipCode": "WN2"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Wigan",
            "zipCode": "WN3"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Wigan",
            "zipCode": "WN4"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Wigan",
            "zipCode": "WN5"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Wigan",
            "zipCode": "WN6"
          },
          {
            "country": "GB",
            "region": "WGN",
            "city": "Leigh",
            "zipCode": "WN7"
          },
          {
            "country": "GB",
            "region": "LAN",
            "city": "Up Holland",
            "zipCode": "WN8"
          },
          {
            "country": "GB",
            "region": "WOR",
            "city": "Tenbury Wells",
            "zipCode": "WR15"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO1"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO10"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Scarborough",
            "zipCode": "YO11"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Scarborough",
            "zipCode": "YO12"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Snainton",
            "zipCode": "YO13"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Hunmanby",
            "zipCode": "YO14"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Sewerby",
            "zipCode": "YO15"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "Bridlington",
            "zipCode": "YO16"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Norton",
            "zipCode": "YO17"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Pickering",
            "zipCode": "YO18"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "Dunnington",
            "zipCode": "YO19"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Whitby",
            "zipCode": "YO21"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Whitby",
            "zipCode": "YO22"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO23"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO24"
          },
          {
            "country": "GB",
            "region": "ERY",
            "city": "North Dalton",
            "zipCode": "YO25"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Tockwith",
            "zipCode": "YO26"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO30"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO31"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO32"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO41"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO42"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO43"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Kirby Hill",
            "zipCode": "YO51"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO60"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Helperby",
            "zipCode": "YO61"
          },
          {
            "country": "GB",
            "region": "YOR",
            "city": "York",
            "zipCode": "YO62"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Sowerby",
            "zipCode": "YO7"
          },
          {
            "country": "GB",
            "region": "NYK",
            "city": "Thorpe Willoughby",
            "zipCode": "YO8"
          }
        ]
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SupplyAdministrator102@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239365,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "automation7639777944",
    "state": "Stopped",
    "tracking-pixel": {}
  },
  {
    "id": "3",
    "deal-id": "DTX3",
    "description": "",
    "from-date": "2024-12-29T00:00:00Z",
    "to-date": "2024-12-29T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": true,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": []
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SupplyAdministrator102@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239365,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "automation5297313881",
    "state": "Pending",
    "tracking-pixel": {}
  },
  {
    "id": "4",
    "deal-id": "DTX4",
    "description": "",
    "from-date": "2024-12-18T00:00:00Z",
    "to-date": "2024-12-21T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": false,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": [
          {
            "country": "AU",
            "region": "NSW"
          },
          {
            "country": "GB",
            "region": "EAL",
            "city": "Ealing",
            "zipCode": "w5"
          },
          {
            "country": "GB",
            "region": "WLS"
          },
          {
            "country": "US",
            "region": "TX",
            "metro": "600"
          }
        ]
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SupplyAdministrator102@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239365,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "automation9501689195",
    "state": "Stopped",
    "tracking-pixel": {}
  },
  {
    "id": "5",
    "deal-id": "DTX5",
    "description": "",
    "from-date": "2024-12-18T00:00:00Z",
    "to-date": "2024-12-18T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": false,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": []
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SupplyAdministrator102@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239365,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "automation1452789808",
    "state": "Stopped",
    "tracking-pixel": {}
  },
  {
    "id": "6",
    "deal-id": "DTX6",
    "description": "",
    "from-date": "2024-12-28T00:00:00Z",
    "to-date": "2024-12-28T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": true,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": []
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SupplyAdministrator102@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239365,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "automation8636045099",
    "state": "Pending",
    "tracking-pixel": {}
  },
  {
    "id": "7",
    "deal-id": "DTX7",
    "description": "Create mysql user for dtx-supply",
    "from-date": "2024-12-17T00:00:00Z",
    "to-date": "2024-12-28T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 10,
    "private-auction": true,
    "priority": false,
    "active": true,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": [
          {
            "country": "UA"
          },
          {
            "country": "IL",
            "region": "TA",
            "city": "Tel Aviv"
          },
          {
            "country": "MX",
            "region": "BCN"
          },
          {
            "country": "US",
            "region": "OR",
            "metro": "813"
          }
        ]
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": [
          {
            "publisher-name": "HeroCraft",
            "publisher-id": 555,
            "publisher-type": "Publisher"
          }
        ]
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": [
          {
            "category-id": 2,
            "category-name": "IAB1-1",
            "category-description": "Books & Literature",
            "category-parent": 1
          }
        ]
      },
      "blockedCategories": {
        "strategy": "include",
        "value": [
          {
            "category-id": 2,
            "category-name": "IAB1-1",
            "category-description": "Books & Literature",
            "category-parent": 1
          }
        ]
      },
      "domainOrBundle": {
        "strategy": "exclude",
        "value": [
          "45435435"
        ]
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "exclude",
        "value": [
          "ru"
        ]
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "yes"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "yes"
      },
      "dnt": {
        "strategy": "include",
        "value": "yes"
      },
      "deviceType": {
        "strategy": "include",
        "value": "tablet"
      },
      "adSizes": {
        "strategy": "include",
        "value": [
          "300x50"
        ]
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "yes"
      },
      "omSupported": {
        "strategy": "include",
        "value": "yes"
      },
      "rewarded": {
        "strategy": "include",
        "value": "yes"
      },
      "displayType": {
        "strategy": "include",
        "value": "video"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "iOS"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SystemAdmin100@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": false,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239363,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 1,
    "fees-json": {
      "rebate": 1,
      "resellerFee": 2,
      "dspFee": 3,
      "techFee": 4,
      "postAuctionDiscount": 0,
      "other": 5,
      "baselineMargin": 6,
      "additionalMargin": 7
    },
    "pricing-type": 1,
    "deal-free-input-name": "RD-6606 after",
    "state": "Activated",
    "tracking-pixel": {}
  },
  {
    "id": "8",
    "deal-id": "DTX8",
    "description": "Create mysql user for dtx-supply",
    "from-date": "2024-12-17T00:00:00Z",
    "to-date": "2024-12-28T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 10,
    "private-auction": true,
    "priority": false,
    "active": true,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": [
          {
            "country": "UA"
          },
          {
            "country": "IL",
            "region": "TA",
            "city": "Tel Aviv"
          },
          {
            "country": "MX",
            "region": "BCN"
          },
          {
            "country": "US",
            "region": "OR",
            "metro": "813"
          }
        ]
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": [
          {
            "publisher-name": "HeroCraft",
            "publisher-id": 555,
            "publisher-type": "Publisher"
          }
        ]
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": [
          {
            "category-id": 2,
            "category-name": "IAB1-1",
            "category-description": "Books & Literature",
            "category-parent": 1
          }
        ]
      },
      "blockedCategories": {
        "strategy": "include",
        "value": [
          {
            "category-id": 2,
            "category-name": "IAB1-1",
            "category-description": "Books & Literature",
            "category-parent": 1
          }
        ]
      },
      "domainOrBundle": {
        "strategy": "exclude",
        "value": [
          "45435435"
        ]
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "exclude",
        "value": [
          "ru"
        ]
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "yes"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "yes"
      },
      "dnt": {
        "strategy": "include",
        "value": "yes"
      },
      "deviceType": {
        "strategy": "include",
        "value": "tablet"
      },
      "adSizes": {
        "strategy": "include",
        "value": [
          "300x50"
        ]
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "yes"
      },
      "omSupported": {
        "strategy": "include",
        "value": "yes"
      },
      "rewarded": {
        "strategy": "include",
        "value": "yes"
      },
      "displayType": {
        "strategy": "include",
        "value": "video"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "iOS"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SystemAdmin100@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": false,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239363,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 1,
    "fees-json": {
      "rebate": 1,
      "resellerFee": 2,
      "dspFee": 3,
      "techFee": 4,
      "postAuctionDiscount": 0,
      "other": 5,
      "baselineMargin": 6,
      "additionalMargin": 7
    },
    "pricing-type": 1,
    "deal-free-input-name": "RD-6606 before",
    "state": "Activated",
    "tracking-pixel": {}
  },
  {
    "id": "9",
    "deal-id": "DTX9",
    "description": "testdeal007A",
    "from-date": "2024-12-17T00:00:00Z",
    "to-date": "2024-12-31T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": true,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": []
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SystemAdmin100@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239363,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "testdeal007A",
    "state": "Activated",
    "tracking-pixel": {}
  },
  {
    "id": "10",
    "deal-id": "DTX10",
    "description": "",
    "from-date": "2024-12-17T00:00:00Z",
    "to-date": "2024-12-17T00:00:00Z",
    "auction-type": 0,
    "bid-floor-price": 0.01,
    "private-auction": false,
    "priority": false,
    "active": false,
    "demand": {},
    "increase-floor-price-by-percentage": 0,
    "increase-floor-price-by-fixed-value": 0,
    "deal-floor-price-strategy": 0,
    "conditions-json": {
      "age": {
        "strategy": "",
        "value": ""
      },
      "gender": {
        "strategy": "",
        "value": ""
      },
      "geo": {
        "strategy": "include",
        "value": [
          {
            "country": "GB",
            "region": "WND",
            "city": "Bedford"
          },
          {
            "country": "GB",
            "region": "BDF",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "MA",
            "metro": "506",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "IN",
            "metro": "527",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "IA",
            "metro": "679",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "KY",
            "metro": "529",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "PA",
            "metro": "574",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "VA",
            "metro": "573",
            "city": "Bedford"
          },
          {
            "country": "US",
            "region": "VA",
            "metro": "544",
            "city": "Suffolk"
          }
        ]
      },
      "appOrSite": {
        "strategy": "",
        "value": ""
      },
      "publishers": {
        "strategy": "include",
        "value": []
      },
      "contents": {
        "strategy": "include",
        "value": []
      },
      "categories": {
        "strategy": "include",
        "value": []
      },
      "blockedCategories": {
        "strategy": "include",
        "value": []
      },
      "domainOrBundle": {
        "strategy": "include",
        "value": []
      },
      "make": {
        "strategy": "",
        "value": null
      },
      "os": {
        "strategy": "",
        "value": null
      },
      "language": {
        "strategy": "include",
        "value": []
      },
      "connectionType": {
        "strategy": "",
        "value": ""
      },
      "muted": {
        "strategy": "include",
        "value": "all"
      },
      "hasIFA": {
        "strategy": "include",
        "value": "all"
      },
      "dnt": {
        "strategy": "include",
        "value": "all"
      },
      "deviceType": {
        "strategy": "include",
        "value": "all"
      },
      "adSizes": {
        "strategy": "include",
        "value": []
      },
      "skippableTraffic": {
        "strategy": "include",
        "value": "all"
      },
      "omSupported": {
        "strategy": "include",
        "value": "all"
      },
      "rewarded": {
        "strategy": "include",
        "value": "all"
      },
      "displayType": {
        "strategy": "include",
        "value": "all"
      },
      "deviceOS": {
        "strategy": "include",
        "value": "all"
      },
      "carrier": {
        "strategy": "",
        "value": null
      },
      "createdBy": "ia.testing.aa+SupplyAdministrator102@gmail.com",
      "audiences": {
        "strategy": "",
        "value": null
      }
    },
    "is-public": true,
    "deal-type": 1,
    "has-targeting": false,
    "created-by": 239365,
    "publisher-id": "",
    "obsolete": false,
    "viewability-tag": 0,
    "fees-json": {
      "rebate": 0,
      "resellerFee": 0,
      "dspFee": 0,
      "techFee": 0,
      "postAuctionDiscount": 0,
      "other": 0,
      "baselineMargin": 27,
      "additionalMargin": 0
    },
    "pricing-type": 1,
    "deal-free-input-name": "automation0819347023",
    "state": "Stopped",
    "tracking-pixel": {}
  }
]
