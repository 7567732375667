import { setupWorker } from "msw";
import { createMockHandlers } from "../../../reference-app/test/mocks/handlers/index";

export class MockedPath {
	constructor(readonly path: string, readonly content: string) {}
}

export interface BrowserMock {
	start(paths: Array<MockedPath>): Promise<boolean>;
}

class ProductionBrowserMock implements BrowserMock {
	async start(): Promise<boolean> {
		return true;
	}
}

class DevelopmentBrowserMock implements BrowserMock {
	async start() {
		// This configures a Service Worker with the given request handlers.
		const handlers = createMockHandlers();
		const worker = setupWorker(...handlers);
		const res = await worker.start();
		return res !== undefined;
	}
}

export const mockBrowser =
	process.env.NODE_ENV === "test" ? new DevelopmentBrowserMock() : new ProductionBrowserMock();
