import { rest } from 'msw'
import { padsList, dspsList, seatsList } from "./data-static";

const getAllPads = rest.get('/api/pads/getAllPads?numOfItems=${rowsPerPage}&page=${pageNumber}', (req, res, ctx) => {
  const page = req.url.searchParams.get('page')
  const numOfItems = req.url.searchParams.get('numOfItems')

  const itemsPerPage = Number(numOfItems)
  const currentPage = Number(page)
  const startIdx = (currentPage - 1) * itemsPerPage // Calculate the start index of the items to be shown on the current page
  const endIdx = startIdx + itemsPerPage;
  const paginatedPads = padsList.slice(startIdx, endIdx) // Get the paginated list by slicing the padsList array from startIdx to endIdx
  const totalCount = padsList.length;
  const totalPages = Math.ceil(totalCount / itemsPerPage) //Math.ceil ensures we round up for incomplete pages


  const data = {
    data: paginatedPads,
    "page-number": Number(page),
    "result-count": paginatedPads.length,
    "total-count": padsList.length,
    "total-pages": totalPages,
  }

  return res(ctx.status(200), ctx.json(data))
})

const getDSPS = rest.get('/api/pads/getDSPS', (_, res, ctx) => {
  return res(ctx.status(200), ctx.json(dspsList))
})


const getValidSeatIdsForPAD = rest.get('/api/pads/getValidSeatIdsForPAD', (req, res, ctx) => {
  const dsp = req.url.searchParams.get('DSP');
  const seatsByDSP = seatsList.filter(seat => seat.dsp_id === Number(dsp))

  return res(ctx.status(200), ctx.json(seatsByDSP[0].seats))
})

const searchSeatsForPAD = rest.get('/api/pads/searchSeatsForPAD', (req, res, ctx) => {
  const dsp = req.url.searchParams.get('DSP');
  const searchTerm = req.url.searchParams.get('SearchTerm');
  const seatsByDSP = seatsList.filter(seat => seat.dsp_id === Number(dsp))
  const filteredSeats = seatsByDSP[0].seats.filter(seat => seat.seat_id === searchTerm)

  return res(ctx.status(200), ctx.json(filteredSeats))
})

const getSeatsByPadId = rest.get('/api/pads/getSeatsByPadId', (req, res, ctx) => {
  const padID = req.url.searchParams.get("PadID")
  const pad = padsList.find(pad => pad.pad_id === Number(padID))

  return res(ctx.status(200), ctx.json(pad.setup_criteria_list))
})

const savePad = rest.post('/api/pads/savePAD', async(req, res, ctx) => {
  const requestBody = await req.json()
  const {curPad} = requestBody
  const dspName = padsList.find(pad => pad.dsp === curPad.dsp).dsp_name

  padsList.push({
    pad_id: padsList.length + 1,
    pad_name: curPad.pad_name,
    marketplace_type: curPad.marketplace_type,
    dsp: curPad.dsp,
    setup_criteria: curPad.setup_criteria,
    discount: curPad.discount,
    active: curPad.active,
    audit: curPad.created_at,
    created_by: requestBody.email,
    dsp_name: dspName,
    setup_criteria_list: curPad.setup_criteria_list
  })


  return res(ctx.status(200), ctx.json(padsList[padsList.length - 1].pad_id))
})


const updatePad = rest.post('/api/pads/updatePAD', async(req, res, ctx) => {
  const requestBody = await req.json()
  const {curPad} = requestBody
  const currentPadIndex = padsList.findIndex(pad => pad.pad_id === curPad.pad_id)
  const currentPadSeatsByDSP = seatsList.find(seatsByDSP => seatsByDSP.dsp_id === curPad.dsp).seats
  const currentPadSeats = currentPadSeatsByDSP.filter(seat => curPad.setup_criteria_list.includes(seat.seat_id))

  curPad.setup_criteria_list = currentPadSeats
  padsList[currentPadIndex] = curPad

  return res(ctx.status(200), ctx.json("Pad Updated Successfully"))
})

const padsRoutes = [
  getAllPads,
  getDSPS,
  getValidSeatIdsForPAD,
  searchSeatsForPAD,
  getSeatsByPadId,
  savePad,
  updatePad
]

export const padsHandlers = () => {
  return padsRoutes
}

