const seatsList = [
  {
    "dsp_id": 600,
    "seats": [
      {"seat_id" : "1"},
      {"seat_id" : "2"},
      {"seat_id" : "3"}
    ]
  },
  {
    "dsp_id": 601,
    "seats": [
      {"seat_id" : "4"},
      {"seat_id" : "5"},
      {"seat_id" : "6"}
    ]
  },
  {
    "dsp_id": 799,
    "seats": [
      {"seat_id" : "7"},
      {"seat_id" : "8"},
      {"seat_id" : "9"}
    ]
  },
  {
    "dsp_id": 800,
    "seats": [
      {"seat_id" : "10"},
      {"seat_id" : "11"},
      {"seat_id" : "12"}
    ]
  },
]

const padsList = [
  {
    "pad_id": 1,
    "pad_name": "pad1",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T10:16:14Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 2,
    "pad_name": "pad2",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:35:34Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video",
    "setup_criteria_list": mockSeatsForPAD(601)
  },
  {
    "pad_id": 3,
    "pad_name": "dsaadas",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:32:54Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 4,
    "pad_name": "dasads",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:20:44Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 5,
    "pad_name": "vcddsf",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:14:17Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 6,
    "pad_name": "dsa",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-11-26T17:01:32Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 7,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T15:45:34Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 8,
    "pad_name": "aaa",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-11-26T15:44:29Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 9,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T15:03:57Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB",
    "setup_criteria_list": mockSeatsForPAD(600)
  },
  {
    "pad_id": 10,
    "pad_name": "test_1",
    "marketplace_type": "PMP",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T13:28:21Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video",
    "setup_criteria_list": mockSeatsForPAD(601)
  },
  {
    "pad_id": 11,
    "pad_name": "test_1",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T13:28:21Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video",
    "setup_criteria_list": mockSeatsForPAD(800)
  },
  {
    "pad_id": 12,
    "pad_name": "test_1",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T13:28:21Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB",
    "setup_criteria_list": mockSeatsForPAD(799)
  },
  {
    "pad_id": 488,
    "pad_name": "testError",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-24T14:37:13Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 487,
    "pad_name": "asafHazann",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": true,
    "audit": "2024-09-24T13:35:58Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 486,
    "pad_name": "testPreLoadSeats",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": true,
    "audit": "2024-09-24T08:01:04Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 485,
    "pad_name": "A1b@C3d$E5f\u0026G7h*I9j!K0l#M2n^O4p(Q)R8s-T_u+Vw=Xy{Z}1a2B3c4D5e6F7g8H9i0Jk!Lm@n#O$p%Qr^St*Uv-Wx+Yz|1A2B3C4D5E6F7G8H9I0J1111111",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-24T05:35:09Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 483,
    "pad_name": "testReset",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-23T19:23:31Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 482,
    "pad_name": "test2",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": false,
    "audit": "2024-09-23T19:07:02Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 481,
    "pad_name": "testNewSearch",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-23T18:46:08Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 478,
    "pad_name": "PAD_RULE_!",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-23T09:54:30Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 480,
    "pad_name": "testSetUpCriteria",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-22T11:20:15Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 479,
    "pad_name": "12333",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": false,
    "audit": "2024-09-19T09:21:12Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 474,
    "pad_name": "22444",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2,
    "active": true,
    "audit": "2024-09-18T13:24:08Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 477,
    "pad_name": "testEdit",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 4,
    "active": true,
    "audit": "2024-09-18T11:07:19Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 222,
    "pad_name": "pad_222",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": true,
    "audit": "2024-09-18T11:03:38Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 111,
    "pad_name": "pad_111",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 2.5,
    "active": true,
    "audit": "2024-09-18T09:19:52Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 476,
    "pad_name": "TestPAd1",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-09-18T07:34:49Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 475,
    "pad_name": "343",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 4,
    "active": true,
    "audit": "2024-09-16T22:12:22Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 473,
    "pad_name": "etes",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-16T07:12:41Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 472,
    "pad_name": "testPadEdit",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-15T11:14:11Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 471,
    "pad_name": "testSort",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-15T09:13:16Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 470,
    "pad_name": "testEditing",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-15T08:18:55Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 469,
    "pad_name": "testEditing",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-15T08:18:23Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 468,
    "pad_name": "testPad",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-15T08:09:26Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 467,
    "pad_name": "testEdit",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-15T08:07:20Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 466,
    "pad_name": "3432",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-14T21:01:22Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 465,
    "pad_name": "testtest",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 2,
    "active": true,
    "audit": "2024-09-12T12:36:23Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 345,
    "pad_name": "pad_345",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": true,
    "audit": "2024-09-12T12:25:18Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 464,
    "pad_name": "testttttt",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T12:23:22Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 463,
    "pad_name": "testtest",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T12:19:48Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 462,
    "pad_name": "testttt",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T12:16:05Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 461,
    "pad_name": "synctest",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 3.5,
    "active": true,
    "audit": "2024-09-12T12:15:19Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 460,
    "pad_name": "testidddd",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T12:13:34Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 459,
    "pad_name": "testid4",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T12:04:30Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 458,
    "pad_name": "testid3",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T11:56:31Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 457,
    "pad_name": "testid2",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 2,
    "active": true,
    "audit": "2024-09-12T11:54:07Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 456,
    "pad_name": "testid",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-12T11:49:18Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 455,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-12T11:48:16Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 454,
    "pad_name": "redirect test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2.5,
    "active": true,
    "audit": "2024-09-12T11:29:10Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 453,
    "pad_name": "padeditbug",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2.5,
    "active": true,
    "audit": "2024-09-12T11:22:20Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 452,
    "pad_name": "bugtestpad",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T11:16:28Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 450,
    "pad_name": "editSaveBug",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T10:48:49Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 449,
    "pad_name": "ertre",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T09:37:13Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 448,
    "pad_name": "35345",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T08:08:09Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 447,
    "pad_name": "r345",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T08:04:15Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 446,
    "pad_name": "kerenTest",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T08:03:02Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 445,
    "pad_name": "kerenTest",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T08:02:05Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 444,
    "pad_name": "kerenTest",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-12T08:00:51Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 443,
    "pad_name": "rt43",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-12T07:39:39Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 442,
    "pad_name": "kerenTest",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 3.5,
    "active": true,
    "audit": "2024-09-12T07:33:48Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 441,
    "pad_name": "343",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-11T23:01:52Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 440,
    "pad_name": "   ",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-11T22:42:53Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 439,
    "pad_name": "test manish",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2.5,
    "active": true,
    "audit": "2024-09-11T13:06:06Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 438,
    "pad_name": "545",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": false,
    "audit": "2024-09-10T23:20:29Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 437,
    "pad_name": "788 ",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-10T23:18:53Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 436,
    "pad_name": "12345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-10T22:53:30Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 435,
    "pad_name": "r43t5",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-10T22:36:48Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 434,
    "pad_name": "we3e",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-10T22:30:19Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 429,
    "pad_name": "43424",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-10T22:27:59Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 428,
    "pad_name": "234",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-10T22:26:05Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 427,
    "pad_name": "e33",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-10T22:24:50Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 426,
    "pad_name": "retet",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-10T22:22:55Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 349,
    "pad_name": "pad_349",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0,
    "active": false,
    "audit": "2024-09-08T12:41:01Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 347,
    "pad_name": "pad_347",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-09-05T09:22:49Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 425,
    "pad_name": "testTest",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-05T09:11:14Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 348,
    "pad_name": "pad_348",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 3,
    "active": true,
    "audit": "2024-09-05T08:45:14Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 350,
    "pad_name": "pad_350",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2,
    "active": true,
    "audit": "2024-09-05T08:45:14Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 351,
    "pad_name": "pad_351",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 4.5,
    "active": false,
    "audit": "2024-09-04T12:56:34Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 123,
    "pad_name": "pad_123",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2.5,
    "active": false,
    "audit": "2024-09-04T12:33:06Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 417,
    "pad_name": "check list type",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 3.5,
    "active": true,
    "audit": "2024-09-04T11:48:36Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 424,
    "pad_name": "asaf",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-09-03T11:35:42Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 423,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": true,
    "audit": "2024-09-02T14:09:47Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 422,
    "pad_name": "pad_123",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-02T08:22:43Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 420,
    "pad_name": "pad_123",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-02T08:02:04Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 419,
    "pad_name": "pad_123",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-02T08:00:22Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 418,
    "pad_name": "pad_123",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-09-02T07:58:05Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 416,
    "pad_name": "test no list",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-08-29T07:18:21Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 413,
    "pad_name": "validSeat",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2.5,
    "active": true,
    "audit": "2024-08-28T13:59:06Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 412,
    "pad_name": "wat now",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-08-28T13:23:43Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 406,
    "pad_name": "wat",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": true,
    "audit": "2024-08-28T12:08:00Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 402,
    "pad_name": "testPADNew",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": true,
    "audit": "2024-08-28T09:26:26Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 399,
    "pad_name": "mor pad wat",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": true,
    "audit": "2024-08-27T14:27:23Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 396,
    "pad_name": "34534",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": false,
    "audit": "2024-08-27T09:54:00Z",
    "created_by": "4@4.notrealdata.fyber.ccccccccom",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 391,
    "pad_name": "373",
    "marketplace_type": "PMP",
    "dsp": 1,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-08-27T05:22:00Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "Inneractive 360"
  },
  {
    "pad_id": 373,
    "pad_name": "mor test123 pad",
    "marketplace_type": "PMP",
    "dsp": 1,
    "setup_criteria": "seatId",
    "discount": 5,
    "active": true,
    "audit": "2024-08-26T13:48:58Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "Inneractive 360"
  },
  {
    "pad_id": 372,
    "pad_name": "mor test123 pad",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 5,
    "active": true,
    "audit": "2024-08-26T13:42:39Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 365,
    "pad_name": "1",
    "marketplace_type": "OX",
    "dsp": 1,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-08-26T09:04:35Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "Inneractive 360"
  },
  {
    "pad_id": 364,
    "pad_name": "pad1",
    "marketplace_type": "PMP",
    "dsp": 1,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-08-26T09:04:16Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "Inneractive 360"
  },
  {
    "pad_id": 355,
    "pad_name": "mor test pad2",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 2,
    "active": true,
    "audit": "2024-08-21T10:58:07Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 354,
    "pad_name": "mor test pad",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 5,
    "active": true,
    "audit": "2024-08-21T10:58:05Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 353,
    "pad_name": "pad_353",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "seatId",
    "discount": 5,
    "active": true,
    "audit": "2024-08-21T10:58:04Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 352,
    "pad_name": "pad_352",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 5,
    "active": true,
    "audit": "2024-08-21T10:58:03Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 346,
    "pad_name": "pad_346",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 5,
    "active": true,
    "audit": "2024-08-21T10:57:56Z",
    "created_by": "asaf.hazan@digitalturbine.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 796,
    "pad_name": "296",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-31T00:24:15Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 795,
    "pad_name": "39364070787130926840413050537665148974729391381222743419459741604544995371732261399959717225009803517092177060148003607222266966035352929969298974485511471943823619822111155306287790440999007816928622",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-31T00:24:01Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 794,
    "pad_name": "821",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-30T00:24:44Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 793,
    "pad_name": "20716159982817277938610422613966464694477164361959388196993621119058056093377035839564125924374052651046263073760168360421533448115200922605822042953176855443673716073493802716762760934083564013599498",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-30T00:24:28Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 792,
    "pad_name": "aaaa",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-12-29T13:21:37Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 791,
    "pad_name": "643",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-29T00:26:08Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 790,
    "pad_name": "96169270435792301406272471808472731165753366838304871779587786929664598404247476419437646404311015456993866378606683914894314868693571145625756070552929546439013708914622304744289702852423218559382932",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-29T00:25:54Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 789,
    "pad_name": "066",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-28T00:25:28Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 788,
    "pad_name": "35458898877892466398489545195591380673960403852716732477599043915294616600312554475462242268193716112832000527750352942082895410001192889695291846918830407752518215388622268691311483827875154323002571",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-28T00:25:13Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 785,
    "pad_name": "automation1531147698",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": true,
    "audit": "2024-12-27T00:26:03Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 787,
    "pad_name": "017",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-27T00:24:34Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 786,
    "pad_name": "98424287556689530667048786737491901146951940169526721249035205871161495306817689648485123929250560965013859620681142201239506135225995347806935011228894467805936780835699563836106196223335371306365113",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-27T00:24:18Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 782,
    "pad_name": "automation6920673395",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-12-26T07:27:18Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 784,
    "pad_name": "832",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-26T06:59:45Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 783,
    "pad_name": "77505909660902732430668122068768860824467732144384539619356057775559155111449748384625671185693015425819418420641297063832982492092204081460841460971503491003577922721083697805641857147146797645329516",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-26T06:59:31Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 781,
    "pad_name": "automation5379493216",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-12-26T06:35:17Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 780,
    "pad_name": "automation6048491847",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-12-26T06:28:49Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 777,
    "pad_name": "automation7846845034",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-12-26T06:25:35Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 779,
    "pad_name": "096",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-26T05:12:43Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 778,
    "pad_name": "51705224857954469275252374669508712343109921959213015115764679636912389511166211585878408781436173616472638048133736968791686289791514235721542595467283439869800659126679908204030645012932245226152304",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-26T05:12:29Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 734,
    "pad_name": "automation9931245435",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-12-26T04:47:06Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 757,
    "pad_name": "423",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-12-26T04:42:14Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 776,
    "pad_name": "%@!#\u0026^$",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-12-26T04:36:02Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 756,
    "pad_name": "84822068794959788449886804518465649949003318581979013603137206129681700576229107892056527468973798352339130496193104535377915719914521859252535706887661233115202653989651463907871642158168228565429023",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-12-26T04:34:31Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 775,
    "pad_name": "RD----",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-12-26T04:34:08Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 774,
    "pad_name": "effy1",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-12-19T09:01:18Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 773,
    "pad_name": "aaaa",
    "marketplace_type": "PMP",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-12-19T09:00:07Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 772,
    "pad_name": "ttre",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-12-18T12:25:38Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 771,
    "pad_name": "4354",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 2.5,
    "active": false,
    "audit": "2024-12-17T11:31:40Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 770,
    "pad_name": "RD-6606 After",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-12-17T11:29:42Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 769,
    "pad_name": "RD-6606 before",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-12-17T11:28:45Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 768,
    "pad_name": "test",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-12-16T09:04:06Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 767,
    "pad_name": "dsa",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1.5,
    "active": false,
    "audit": "2024-12-16T09:03:45Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 766,
    "pad_name": "saddsd",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:35:34Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 765,
    "pad_name": "dsaadas",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:32:54Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 764,
    "pad_name": "dasads",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:20:44Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 763,
    "pad_name": "vcddsf",
    "marketplace_type": "OX",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-27T09:14:17Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 762,
    "pad_name": "dsa",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-11-26T17:01:32Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 761,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T15:45:34Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 760,
    "pad_name": "aaa",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-11-26T15:44:29Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 759,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T15:03:57Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 758,
    "pad_name": "test_1",
    "marketplace_type": "PMP",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-26T13:28:21Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 755,
    "pad_name": "seatdid test",
    "marketplace_type": "PMP",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 1,
    "active": true,
    "audit": "2024-11-25T12:59:29Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 754,
    "pad_name": "338",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-25T00:23:43Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 753,
    "pad_name": "38198216171426648978490736569059172475119983574141809625070237056192265600913258744259256984083076686057380173875901568121637240649524612492362662220802100017063651239832600183797490017066715650550077",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-25T00:23:29Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 744,
    "pad_name": "4543",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-24T09:35:20Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 752,
    "pad_name": "133",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-24T00:25:28Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 751,
    "pad_name": "25379352160877540975296044105752574079553082757217032478664424621809280855596437431132091236688109210900191778745942719684850074956696934485130961916624878988139676589997709445303487861393443732971074",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-24T00:25:13Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 750,
    "pad_name": "547",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-23T00:25:30Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 749,
    "pad_name": "68074466784566140837485118146794655402602135232061421048468405057645470920146989201632531295506886220988833683732422008200214984704841838623632909875106798460546322282733820182791451915999798911311146",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-23T00:25:14Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 748,
    "pad_name": "407",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-22T00:24:57Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 747,
    "pad_name": "76919784863136111658869641608365399997895434656307694960467680918626912231401499538039914182137660329716065837958976843474689596641009384835752917866761579465834319539769948403983330914525470305836938",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-22T00:24:42Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 746,
    "pad_name": "578",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-21T00:16:43Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 745,
    "pad_name": "45175873512576335682593634667234785687911324825609262940244598078225820054445020188715904280115214309305326753081175939179783127338411563153435401028877950858079777281372845766284946927883732105574063",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-21T00:16:27Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 743,
    "pad_name": "4543",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "seatId",
    "discount": 2,
    "active": true,
    "audit": "2024-11-20T06:23:17Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 742,
    "pad_name": "25435",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "dealId",
    "discount": 7.5,
    "active": true,
    "audit": "2024-11-20T06:22:53Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 741,
    "pad_name": "e45",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-20T06:19:52Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 740,
    "pad_name": "34534",
    "marketplace_type": "PMP",
    "dsp": 601,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-20T06:18:22Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 739,
    "pad_name": "44345",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-20T06:18:08Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 738,
    "pad_name": "435",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-20T06:06:26Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 737,
    "pad_name": "2423",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "dealId",
    "discount": 1,
    "active": false,
    "audit": "2024-11-20T06:06:05Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 736,
    "pad_name": "418",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-20T04:51:43Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 735,
    "pad_name": "40746752849346696671459395045843235517928096204134525135835277635420693103942392507878901756491729799177753853526994210395358237364548550455908590725393701658188775472046827072472287126057181781019064",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-20T04:51:29Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 727,
    "pad_name": "automation2752465222",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-11-20T04:32:57Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 733,
    "pad_name": "721",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-20T00:23:56Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 732,
    "pad_name": "52524057963186339767240320865230630437489976219351654665329193062055746263280954357935742920760178783522937051552105819015990041308883360818683843855761941479505036654858231472415329672848966014918047",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-20T00:23:42Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 731,
    "pad_name": "newnewne",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "dealId",
    "discount": 2.5,
    "active": true,
    "audit": "2024-11-19T08:56:43Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 729,
    "pad_name": "869",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-19T00:23:57Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 728,
    "pad_name": "10244800788764582552131431270139393513656844916955299122737832424053029571231012029769087887410025706507315238539527539763423541350018545908456769196382799357795187494797840570241603599369452202798633",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-19T00:23:41Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 726,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-18T13:28:16Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 725,
    "pad_name": "nnnnn",
    "marketplace_type": "PMP",
    "dsp": 601,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-18T13:28:01Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 724,
    "pad_name": "sdcasdsad",
    "marketplace_type": "PMP",
    "dsp": 799,
    "setup_criteria": "dealId",
    "discount": 2,
    "active": false,
    "audit": "2024-11-18T12:50:38Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 723,
    "pad_name": "test",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "dealId",
    "discount": 0.5,
    "active": true,
    "audit": "2024-11-18T12:46:20Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 715,
    "pad_name": "automation2037402307",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-18T04:33:31Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 716,
    "pad_name": "12176472600405230274852922471722363154964705956495052370440659471335373113119860034368167086804430353747919256280196841355195511179962559698882994164599334499600014936925186006419565238558966055369820",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-18T04:33:23Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 720,
    "pad_name": "dspid_test",
    "marketplace_type": "PMP",
    "dsp": 600,
    "setup_criteria": "dealId",
    "discount": 2,
    "active": false,
    "audit": "2024-11-18T04:32:49Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "pad_id": 722,
    "pad_name": "687",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-18T00:24:51Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 721,
    "pad_name": "dspId-test2",
    "marketplace_type": "OX",
    "dsp": 799,
    "setup_criteria": "dealId",
    "discount": 1.5,
    "active": false,
    "audit": "2024-11-17T13:01:18Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB"
  },
  {
    "pad_id": 719,
    "pad_name": "500",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-17T00:26:07Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 718,
    "pad_name": "940",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-16T00:25:28Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 717,
    "pad_name": "921",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-15T00:23:32Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 710,
    "pad_name": "automation8299609348",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-14T06:00:27Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 713,
    "pad_name": "34225791738266840118007011188983207128448731529285170700723025345109633862828829205841420011408757088074520322643911072015731439108736450752548337040829889875098110751185622076421236712252235727305149",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-14T06:00:14Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 714,
    "pad_name": "246",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-14T05:49:24Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 711,
    "pad_name": "77069975754340288669128562251026962464660962510153252139081942274043071527530417356728440141617349961032150593725842642606719936532535410563024291934775939619931916963109534859052301355967708611619640",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-14T05:35:09Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 712,
    "pad_name": "177",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-14T00:26:06Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 709,
    "pad_name": "861",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-13T07:39:21Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 708,
    "pad_name": "85261931217629262234346223361290495641131851100426239791346644163534446810988020523420575241048360096559732560418888474868566377536937100384502162189728131196856418352475100117461744072193623088573711",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-13T07:38:56Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 707,
    "pad_name": "automation9360833794",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-11-13T07:38:04Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 706,
    "pad_name": "623",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-13T07:30:49Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 705,
    "pad_name": "49806813349552311417053821853921011561239243289809800190364225035039058672181329090851894185737426940464151069307532920363862244077584379664704504983275145605655050023261667004146745254176485917589607",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-13T07:30:24Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 704,
    "pad_name": "automation9899284370",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-11-13T07:29:31Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 701,
    "pad_name": "automation4189921210",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-11-13T06:59:21Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 703,
    "pad_name": "689",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-13T06:26:45Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 702,
    "pad_name": "49609819401226136696898817766606806445446783879318627483209385368989530137854363146711932780381498880937025036226174461459130046937049494978225929565786375003533757610201038261939985064027405951743834",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-13T06:26:31Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 688,
    "pad_name": "automation8829144378",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 5.5,
    "active": false,
    "audit": "2024-11-13T06:04:44Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 699,
    "pad_name": "00079410985114664352679917486588528360359596104845934479053527593098122899800146257601251357546970923996666385916577994242469269003813455477992195921360673641418730617704983793594985527739363408376187",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-13T06:00:50Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 700,
    "pad_name": "572",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-13T04:48:19Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "pad_id": 697,
    "pad_name": "29535918910363972637845429882138821992781296787536707147094788304107242340375789047575900891905523355472356990243789579812017941473728440667114055916003542305734708358985828838678501312440435192970636",
    "marketplace_type": "PMP",
    "dsp": 800,
    "setup_criteria": "seatId",
    "discount": 10,
    "active": false,
    "audit": "2024-11-13T04:21:09Z",
    "created_by": "ia.testing.aa+SystemAdmin100@gmail.com",
    "dsp_name": "DV360_RTB_Video"
  },
  {
    "pad_id": 698,
    "pad_name": "296",
    "marketplace_type": "OX",
    "dsp": 601,
    "setup_criteria": "seatId",
    "discount": 0.5,
    "active": false,
    "audit": "2024-11-13T00:17:21Z",
    "created_by": "ia.testing.aa+SupplyAdministrator102@gmail.com",
    "dsp_name": "TheTradeDesk_RTB_Video"
  }
]

const dspsList = [
  {
    "dsp_id": 600,
    "dsp_name": "TheTradeDesk_RTB"
  },
  {
    "dsp_id": 601,
    "dsp_name": "TheTradeDesk_RTB_Video"
  },
  {
    "dsp_id": 799,
    "dsp_name": "DV360_RTB"
  },
  {
    "dsp_id": 800,
    "dsp_name": "DV360_RTB_Video"
  }
]

function mockSeatsForPAD(dspID: number) {
  const seatsByDSP = seatsList.find(seatsByDSP => seatsByDSP.dsp_id === dspID)
  const randomValue = Math.floor(Math.random() * seatsByDSP.seats.length)

  return [seatsByDSP.seats[randomValue]]
}

export { padsList, dspsList, seatsList }
