import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Grid, FormControlLabel, Checkbox, CardHeader, Card, CardContent
} from "@mui/material";
import { Controller } from 'react-hook-form';
import Divider from "@mui/material/Divider";
import { ExistsIFAs } from "./userControlTargeting";
import { useEffect, useState } from "react";

interface SupplyTargetingProps {
  control: any; // Replace with the correct type from react-hook-form
  displayType: any;
  setValue: Function;
}

export const SupplyTraffic  ={
  all: "All",
  yes: "Yes",
  no: "No"
}

export const PerDspSettings = 0 ;

export const DisplayType = {
  all: "All",
  video: "Video",
  display: "Display",
}

export enum CheckboxOptions {
  "300x50" = "300x50",
  "300x250" = "300x250",
  "320x50" = "320x50",
  "320x250" = "320x250",
  "728x90" = "728x90",
  "320x480" = "320x480",
  "480x320" = "480x320",
  "768x1024" = "768x1024",
  "1024x768" = "1024x768",
}

export type AdExperience = {
  id: number,
  name: string
}

export default function SupplyTargeting( props:SupplyTargetingProps) {
  const { control, setValue , displayType} = props
  const [adExperiencesVideo,setAdExperiencesVideo] = useState([]);
  const fetchAdExperienceVideo = async () => {
    try {
      const response = await fetch("/api/deals-aux/get-ad-experiences",{
        method: 'get',
        mode: 'cors',
    });
      const adExperiences = await response.json();
      const resultsWithDefaultOption: AdExperience[] = [{ id: PerDspSettings, name: "Per DSP settings" }, ...adExperiences];
      setAdExperiencesVideo(resultsWithDefaultOption);
    }
    catch (e) {
      console.log("Error fetching Ad Experience Video data");
      throw new Error(e);
    }
  }

  useEffect(() => {
    fetchAdExperienceVideo();
  }, []);

  return (
    <Card variant={"outlined"} style={{marginTop:12}}>
      <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Supply Targeting"} />
      <CardContent>
      <Controller
        name="deal.conditions-json.skippableTraffic.value"
        control={control}
        // rules={{ required: 'Device OS selection is required' }}
        render={({ field, fieldState, formState }) => {
          return (
          <>
            <FormControl fullWidth margin="normal">
            <InputLabel id="skippableTraffic-tag-select-label" style={{ paddingTop: "10px"}}>Skippable Traffic</InputLabel>
            <Select
              onChange={(e) => {
                field.onChange(e)
              }}
              labelId="skippableTraffic-tag-select-label"
              label="Skippable Traffic"
              error={!!fieldState.error}
              value={field.value ?? ''}
              ref={field.ref}
            >
              {Object.entries(SupplyTraffic).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {SupplyTraffic[key]}
                </MenuItem>))}
            </Select>
            {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          </>
        )}}
      />
      <Controller
        name="deal.conditions-json.omSupported.value"
        control={control}
        // rules={{ required: 'Device OS selection is required' }}
        render={({ field, fieldState, formState }) => (
          <>
            <FormControl fullWidth margin="normal">
            <InputLabel id="omSupported-tag-select-label" style={{ paddingTop: "10px"}}>OM Supported</InputLabel>
            <Select
              {...field}
              labelId="omSupported-tag-select-label"
              label="OM Supported"
              error={!!fieldState.error}
              value={field.value ?? ''}
              ref={field.ref}
            >
              {Object.entries(SupplyTraffic).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {SupplyTraffic[key]}
                </MenuItem>))}
            </Select>
            {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          </>
        )}
      />
      <Controller
        name="deal.conditions-json.displayType.value"
        control={control}
        render={({ field, fieldState }) => (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel id="displayType-tag-select-label" style={{ paddingTop: "10px" }}>Display Type</InputLabel>
              <Select
                {...field}
                labelId="displayType-tag-select-label"
                label="Display Type"
                error={!!fieldState.error}
                value={field.value ?? ''}
                ref={field.ref}
                onChange={(e) => {
                 field.onChange(e);
                 setValue("deal.ad-experience-video",PerDspSettings);
                }}
              >
                {Object.entries(DisplayType).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  {DisplayType[key]}
                </MenuItem>))}
              </Select>
              {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
            </FormControl>
          </>
        )}
      />

      <Controller
        name="deal.conditions-json.rewarded.value"
        control={control}
        render={({ field, fieldState, formState }) => (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel id="rewarded-tag-select-label" style={{ paddingTop: "10px" }}>
                Rewarded
              </InputLabel>
              <Select
                {...field}
                labelId="rewarded-tag-select-label"
                label="Rewarded"
                error={!!fieldState.error}
                value={field.value ?? ''}
                onChange={(e) => {
                  field.onChange(e)
                }}
                ref={field.ref}
              >
                {Object.entries(SupplyTraffic).map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {SupplyTraffic[key]}
                  </MenuItem>))}
              </Select>
              {fieldState.error && (
                <FormHelperText error>{fieldState.error.message}</FormHelperText>
              )}
            </FormControl>
          </>
        )}
      />
        <Controller
          name="deal.ad-experience-video"
          control={control}
          render={({ field, fieldState, formState }) => (
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel id="ad-overlay-experience-video-label" style={{ paddingTop: "10px" }}>
                  Ad Overlay Experience (Only applies to Video Ads)
                </InputLabel>
                <Select
                  {...field}
                  labelId="experience-tag-select-label"
                  label="adExperienceVideo"
                  error={!!fieldState.error}
                  value={field.value ?? ''}
                  ref={field.ref}
                  disabled={displayType === "display"}
                >
                  {adExperiencesVideo.map((adExperienceObject: AdExperience) => (
                    <MenuItem value={adExperienceObject.id} key={adExperienceObject.id}>
                      {adExperienceObject.name}
                    </MenuItem>))}
                </Select>
                {fieldState.error && (
                  <FormHelperText error>{fieldState.error.message}</FormHelperText>
                )}
              </FormControl>
            </>
          )}
        />
      <InputLabel id="rewarded-tag-select-label" style={{ paddingTop: "10px"}}>Ad Sizes</InputLabel>
      <Grid container spacing={2}>
        {Object.entries(CheckboxOptions).map(([key, option]) => (
          <Grid item xs={4} key={key}>
            <Controller
              name={`deal.conditions-json.adSizes.value`}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Array.isArray(field.value) && field.value.includes(option)}
                        onChange={(e) => {
                          let newValue;
                          if (e.target.checked) {
                            newValue = Array.isArray(field.value) ? [...field.value, option] : [option];
                          } else {
                            newValue = Array.isArray(field.value)
                              ? field.value.filter((item) => item !== option)
                              : [];
                          }
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={option}
                  />
                </FormControl>
              )}
            />
          </Grid>
        ))}
      </Grid>


      </CardContent>
    </Card>

  )
}
