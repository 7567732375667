import { useContext, useEffect, useState } from "react";
import { SnackbarNotificationsContext } from "@dtx/ui-core/src/context/SnackbarNotificationsProvider";
import Cookies from 'js-cookie';

interface User {
  id: string
  name: string
  email: string
}

interface Token {
  id: string
  name: string
  email: string
  exp: number
  orig_iat: number
}

function createUser(token: string): User {
  const jwt = parseJwt(token)
  return {
    id: jwt.id,
    email: jwt.email,
    name: jwt.name
  }
}

function getEmptyUser(): User {
  return {
    id: "",
    email: "",
    name: ""
  }
}

function getEmptyToken(): Token {
  return {
    id: "",
    name: "",
    email :"",
    exp: 0,
    orig_iat: 0
  }
}

export function parseJwt(token: string): Token {
  const base64Payload = token.split('.')[1]
  try {
    const jsonPayload = window.atob(base64Payload)
    return JSON.parse(jsonPayload) as Token
  } catch (error) {
    console.error('Could not parse JWT', error)
  }
  return getEmptyToken()
}

const getSessionToken =  async(consoleToken: string) => {
  const response = await fetch("/auth", {
    method: 'POST',
    credentials: 'include',
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      token: consoleToken,
    }),
  });

  const data = await response.json();
  if (response.ok) {
    return data.deals_token as string
  } else {
    console.log(`Authentication failed!`);
  }
}
export function useAuthentication() {
  const consoleToken = new URLSearchParams(location.search).get("token")
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const { showNotification } = useContext(SnackbarNotificationsContext)

  useEffect(() => {
    const sessionToken = Cookies.get("deals-token")
    if(sessionToken) {
      setIsAuthenticated(true)
      return
    }

    if (!consoleToken) {
      const sessionToken = Cookies.get("deals-token")
      if (process.env.NODE_ENV !== "production") {
        setIsAuthenticated(true)
        return
      }
      if (sessionToken) {
        setIsAuthenticated(true)
        return
      }
      showNotification("error", "Authentication failed")
      return
    }

    authenticate(consoleToken)

    async function authenticate(consoleToken: string) {
      const sessionToken = await getSessionToken(consoleToken)

      if (!sessionToken) {
        showNotification("error", "Authentication failed")
        return
      }
      setIsAuthenticated(true)
    }


  }, [consoleToken])


  return { isAuthenticated }
}

export function useUserSession(){
  const sessionToken = Cookies.get("deals-token")
  if (sessionToken) {
    return {user: createUser(sessionToken)}
  }
  else {
    return {user: getEmptyUser()}
  }
}