import { rest } from 'msw'
import { dealsList } from "./data-static";

const getAllDeals = rest.get('/api/deals', (req, res, ctx) => {
  const page = req.url.searchParams.get('page')
  const numOfItems = req.url.searchParams.get('numOfItems')

  const itemsPerPage = Number(numOfItems)
  const currentPage = Number(page)
  const startIdx = (currentPage - 1) * itemsPerPage // Calculate the start index of the items to be shown on the current page
  const endIdx = startIdx + itemsPerPage;
  const paginatedDeals = dealsList.slice(startIdx, endIdx) // Get the paginated list by slicing the padsList array from startIdx to endIdx
  const totalCount = dealsList.length;
  const totalPages = Math.ceil(totalCount / itemsPerPage) //Math.ceil ensures we round up for incomplete pages


  const data = {
    data: paginatedDeals,
    "page-number": Number(page),
    "result-count": paginatedDeals.length,
    "total-count": dealsList.length,
    "total-pages": totalPages,
  }
  return res(ctx.status(200), ctx.json(data))
})

const dealsRoutes = [
  getAllDeals
]

export const dealsHandlers = () => {
  return dealsRoutes
}

